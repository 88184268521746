import _max from "lodash/max";
import _min from "lodash/min";

import { IChartSeries } from "models/chart";

import { ALL_CHART_TYPES } from "../../constants";

type AxisType = "x" | "y";

const getSeriesMinMax = (
  axis: AxisType,
  series: Array<IChartSeries>,
  chartType: string
): [number | string, number | string] => {
  if (!series || !series.length || !series[0][axis]) return [0, 0];

  // Filter out the "well count" series as it appears on the secondary y-axis and not part of the min/max calculation.
  const filteredSeries: IChartSeries[] = series.filter((s) => s.label !== "Well Count");

  if (
    chartType === ALL_CHART_TYPES.StackedBar.label ||
    chartType === ALL_CHART_TYPES.TotalRateDate.label
  ) {
    // Create a map to sum y values for each x category.
    const summedValues: { [key: string]: number } = {};

    filteredSeries.forEach((series) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series[axis].forEach((value: any, index: number) => {
        if (axis === "y") {
          const xCategory = index;
          const isNewCategory = !summedValues[xCategory];
          if (isNewCategory) {
            // This ensures that the property exists before adding the y value to it.
            summedValues[xCategory] = 0;
          }
          summedValues[xCategory] += value === "" ? 0 : (value as number);
        }
      });
    });

    const allSummedValues = Object.values(summedValues);
    const min = _min(allSummedValues);
    const max = _max(allSummedValues);

    return [min, max];
  }

  // Default behavior for other chart types.
  const allMinList = filteredSeries.map((s) => _min(s[axis]));
  const allMaxList = filteredSeries.map((s) => _max(s[axis]));

  const min = _min(allMinList);
  const max = _max(allMaxList);

  return [min, max];
};

export default getSeriesMinMax;
