import { FC, useEffect, useState } from "react";

import { InputNumber, Modal } from "antd";
import moment from "moment";
import styled from "styled-components";

import { OnTime } from "components/forecasting/models/forecastWell";

interface OnTimeEditorModalProps {
  onTime?: OnTime[];
  open: boolean;
  startDate: number;
  onChange?: () => void;
  onCancel?: () => void;
  onOk?: (time: OnTime[]) => void;
}

const OnTimeEditorModal: FC<OnTimeEditorModalProps> = ({
  open,
  onTime,
  startDate,
  onCancel,
  onOk
}) => {
  const [onTimeArray, setOnTimeArray] = useState<OnTime[]>([]);

  useEffect(() => {
    if (!onTime) {
      return;
    }
    const date = new Date(startDate);
    const momentDate = moment(
      `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}`,
      "YYYY-MM"
    );

    const first12Months = Array.from({ length: 12 }, (_, i) => {
      const newDate = momentDate.clone().add(i, "months");
      return { month: `${newDate.format("YYYY-MMM")}`, value: null } as OnTime;
    });
    const currentOnTime = [...first12Months];
    for (const month of currentOnTime) {
      const momentDate = moment(month.month, "YYYY-MMM");
      for (const setOnTime of onTime) {
        const onTimeDate = moment(setOnTime.month).toDate();
        const sameYear = momentDate.get("year") === onTimeDate.getUTCFullYear();
        const sameMonth = momentDate.get("month") === onTimeDate.getUTCMonth();
        if (sameYear && sameMonth) {
          month.value = setOnTime.value * 100.0;
        }
      }
    }
    setOnTimeArray(first12Months);
  }, [onTime, startDate]);

  return (
    <Modal
      open={open}
      onOk={() => onOk(onTimeArray.filter((item) => item.value !== null))}
      onCancel={onCancel}
      title={<ModalHeader>Set On Time</ModalHeader>}>
      <RowWrapper>
        <label>Month</label>
        <label>On Time (%)</label>
      </RowWrapper>
      {onTimeArray.map((monthValue, i) => {
        return (
          <RowWrapper key={monthValue.month}>
            <label>{monthValue.month}</label>
            <InputNumber
              value={monthValue.value}
              min={0}
              max={100}
              onChange={(pct) => {
                const update = [...onTimeArray];
                update.splice(i, 1, {
                  month: monthValue.month,
                  value: pct
                });
                setOnTimeArray(update);
              }}
            />
          </RowWrapper>
        );
      })}
    </Modal>
  );
};

export default OnTimeEditorModal;

const RowWrapper = styled.div`
  display: flex;
  margin-bottom: 0.2rem;
  label {
    padding: 5px;
    width: 100px;
  }
`;

const ModalHeader = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;
