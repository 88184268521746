/**
 * Adds a specified number of months to a given date, accounting for fractional months.
 * This function returns a new date object with the added months in UTC, maintaining the original timestamp.
 */
export default function addMonthsToDate(date_utc: Date, monthsToAdd: number): Date {
  const newDate = new Date(
    Date.UTC(
      date_utc.getUTCFullYear(),
      date_utc.getUTCMonth(),
      date_utc.getUTCDate(),
      date_utc.getUTCHours(),
      date_utc.getUTCMinutes(),
      date_utc.getUTCSeconds(),
      date_utc.getUTCMilliseconds()
    )
  );
  const wholeMonthsToAdd = Math.floor(monthsToAdd);
  const partialMonthToAdd = monthsToAdd - wholeMonthsToAdd;

  const resultingMonth = newDate.getUTCMonth() + wholeMonthsToAdd;
  newDate.setUTCMonth(resultingMonth);

  // Adjust for cases where adding months results in an invalid date
  // e.g., adding 1 month to January 31st results in March 3rd instead of February 28th/29th
  if (newDate.getUTCMonth() !== ((resultingMonth % 12) + 12) % 12) {
    newDate.setUTCDate(0);
  }

  if (partialMonthToAdd > 0) {
    const daysInMonth = new Date(
      Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth() + 1, 0)
    ).getUTCDate();
    const additionalDays = Math.round(partialMonthToAdd * daysInMonth);
    newDate.setUTCDate(newDate.getUTCDate() + additionalDays);
  }

  return newDate;
}
