import { Shapefile } from "components/project/shapefiles/queries";

export function getUncheckedFeatures(
  layerName: string,
  shapefileFeatures,
  shapefile: Shapefile
): string[] {
  if (shapefileFeatures?.[layerName]?.features) {
    let uncheckedFeatures = shapefileFeatures?.[layerName]?.features
      .filter((f) => f.checked)
      .map((f) => f.shapefileGeomId);
    if (!uncheckedFeatures?.length) {
      uncheckedFeatures = [""];
    }
    return uncheckedFeatures;
  }

  return shapefile.featureCollection?.features.map((f) => f.properties.shapefileGeomId);
}
