import { memo } from "react";

interface PlaceholderBinProps {
  color?: string;
}

const PlaceholderBin: React.FC<PlaceholderBinProps> = ({ color = "#a2aaad" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="291.481 197.0956 27.609 26.0424"
      width="16.7px"
      height="16.7px">
      <g id="object-2">
        <g transform="matrix(1, 0, 0, 1, 291.894836, 197.095596)" id="object-0">
          <path
            style={{ fill: color }}
            d="M21.125,2C22.71,2,24,3.29,24,4.875v16.25C24,22.71,22.71,24,21.125,24H4.875 C3.29,24,2,22.71,2,21.125V4.875C2,3.29,3.29,2,4.875,2H21.125 M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25 C0,23.818,2.182,26,4.875,26h16.25C23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0L21.125,0z"
          />
          <path
            style={{ fill: color }}
            d="M13.301,6.85c-2.798,0-4.535,2.428-4.535,6.337c0.02,3.863,1.702,6.263,4.392,6.263 c2.882,0,4.535-2.329,4.535-6.39C17.692,9.171,16.051,6.85,13.301,6.85z M13.229,17.257c-1.084,0-1.73-1.529-1.73-4.09 c0-2.544,0.67-4.125,1.749-4.125c1.415,0,1.712,2.214,1.712,4.071C14.959,14.661,14.734,17.257,13.229,17.257z"
          />
        </g>
        <path
          style={{ fill: color, stroke: color, strokeWidth: "2px" }}
          d="M 291.481 223.138 L 319.09 197.113"
          id="object-1"
          transform="rotate(90 305.285 210.125)" // Rotate 180 degrees around the center of the line
        />
      </g>
    </svg>
  );
};

export default memo(PlaceholderBin);
