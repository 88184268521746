import { ProjectTreeNodeT } from "components/project/layers/hooks/project-layer-tree/useProjectLayerTree";

export function useCheckableLayerKeys(tree: ProjectTreeNodeT[]): Set<string> {
  const checkableLayerKeys = new Set<string>();

  function traverseTree(nodes: ProjectTreeNodeT[]) {
    nodes.forEach((node) => {
      if (node.type === "layer") {
        checkableLayerKeys.add(node.key);
      }
      if (node.children) {
        traverseTree(node.children);
      }
    });
  }

  traverseTree(tree);
  return checkableLayerKeys;
}
