import { rest } from "msw";

import { numberToRescatString } from "components/sync/util";

const PDP_SERVICE_ROOT = process.env.REACT_APP_JOB_SERVICE;
const jobListHandler = rest.get(`${PDP_SERVICE_ROOT}/api/v1/job`, (_, res, ctx) => {
  const jobList = ["Job 1", "Job 2", "Job 3"]; // Your list of strings

  return res(ctx.json(jobList));
});

const jobHierarchyHandler = rest.get(
  `${PDP_SERVICE_ROOT}/api/v1/hierarchy/:job`,
  (_, res, ctx) => {
    const hierarchyList = [
      "McD Forecast 1 Priority",
      "McD Forecast 2 Warnings",
      "McD Forecast 3 Play-Region",
      "McD Forecast 4 Operator"
    ]; // Your list of strings
    return res(ctx.json(hierarchyList));
  }
);

const wellInHierarchyHandler = rest.get(
  `${PDP_SERVICE_ROOT}/api/v1/hierarchy/:job/:hierarchy`,
  (_, res, ctx) => {
    const hierarchyList = [
      {
        children: [
          {
            children: [
              {
                children: [
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "102/06-21-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380321060200"
                    },
                    id: "23449ad0a64d460aa1cf540acab442e6"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/11-21-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380321110000"
                    },
                    id: "778bdf5acc064ecb9eb46ef2ec7f9c04"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/14-21-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380321140000"
                    },
                    id: "9f8edf7156e442a3b700fcc35dcb5748"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "104/15-21-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380321150400"
                    },
                    id: "45013c724586408ebbd4b4683acd3b40"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "104/16-21-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380321160400"
                    },
                    id: "c13786e0507a454ca094709a1c5bb590"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "104/16-21-038-03W4/2",
                      approvedBy: "",
                      sortingId: "A140380321160402"
                    },
                    id: "9d920a45b9744006bc25f4b35d4790e4"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "105/16-21-038-03W4/0 LOC",
                      approvedBy: "",
                      sortingId: "A140380321160500 LOC"
                    },
                    id: "e20b73001a924fda8b6788d4245e0cfc"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "105/03-22-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380322030500"
                    },
                    id: "4d2f481b47fd465c8a9a197c539ad3e9"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "105/05-22-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380322050500"
                    },
                    id: "daa28b7369444a39b826154371a90f00"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "106/05-22-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380322050600"
                    },
                    id: "6ea9172dc89042b58b6bece945dc17a2"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "102/12-22-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380322120200"
                    },
                    id: "4c91e5256cdc4e6a95e313340d4b4551"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "105/13-22-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380322130500"
                    },
                    id: "1a39e0be37c14e388866dc221f04b9a4"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/14-22-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380322140000"
                    },
                    id: "0a01e265e9d94614850bc48bead701bd"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/02-27-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380327020000"
                    },
                    id: "438b2a78181949a1ac5d4a6196df764d"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/10-27-038-03W4/2",
                      approvedBy: "",
                      sortingId: "A140380327100002"
                    },
                    id: "64240bbe5fa14ba7a0ccab38db0ff960"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "103/15-27-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380327150300"
                    },
                    id: "56f982b6ffe6484e9ffc61ef54056c33"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/16-27-038-03W4/2",
                      approvedBy: "",
                      sortingId: "A140380327160002"
                    },
                    id: "0ab7843cb7644a039b0d61d83a959089"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/08-28-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380328080000"
                    },
                    id: "e2e04c9d7df34fb79d2e7a63d2503afe"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "102/09-28-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380328090200"
                    },
                    id: "8522e6baadb848e39989bcc0904fc473"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "104/09-28-038-03W4/0 LOC",
                      approvedBy: "",
                      sortingId: "A140380328090400 LOC"
                    },
                    id: "a0bfdb96cfa34c0d82ed14710f18105d"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "103/04-29-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A140380329040300"
                    },
                    id: "eea23e4c60784a20b9f1ef441ad34805"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "1D0/04-29-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A14038032904D000"
                    },
                    id: "953d572b86674f3ea894e9138080ddf2"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "1D0/04-29-038-03W4/2",
                      approvedBy: "",
                      sortingId: "A14038032904D002"
                    },
                    id: "5601eeb819204aa3beb73b326fee129a"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "1B0/06-29-038-03W4/0",
                      approvedBy: "",
                      sortingId: "A14038032906B000"
                    },
                    id: "88f8c1dce525477590ba9cda3f57ca0e"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "1C0/06-29-038-03W4/2",
                      approvedBy: "",
                      sortingId: "A14038032906C002"
                    },
                    id: "ab7628d168ad4839b2315f7a1eab05d4"
                  }
                ],
                value: {
                  approved: false,
                  value: "Provost",
                  approvedBy: "",
                  sortingId: "A140380321060200"
                },
                id: "f2fec58b5771484a87d7196680fe4984"
              },
              {
                children: [
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/04-09-088-05W5/0",
                      approvedBy: "",
                      sortingId: "A150880509040000"
                    },
                    id: "b273a8f52e354154bfe235967ea15917"
                  },
                  {
                    children: [],
                    value: {
                      approved: false,
                      value: "100/01-04-089-06W5/0",
                      approvedBy: "",
                      sortingId: "A150890604010000"
                    },
                    id: "2f050c578a9e47c58bd089b3101fe567"
                  }
                ],
                value: {
                  approved: false,
                  value: "Red Earth",
                  approvedBy: "",
                  sortingId: "A150880509040000"
                },
                id: "0255d84a13de4a0dbcefdbc9aeebd347"
              }
            ],
            value: {
              approved: false,
              value: "Alberta",
              approvedBy: "",
              sortingId: "A140380321060200"
            },
            id: "d52172a4064a48ceb7ba2a9056b22354"
          }
        ],
        value: {
          approved: false,
          value: "Canada",
          approvedBy: "",
          sortingId: "A140380321060200"
        },
        id: "eb738de752734378993fbb8bbce32971"
      }
    ]; // Your list of strings
    return res(ctx.json(hierarchyList));
  }
);

const typeWellLengthsHandler = rest.post(
  `${PDP_SERVICE_ROOT}/api/v1/hierarchy/:job/lengths`,
  (_, res, ctx) => {
    return res(ctx.json({}));
  }
);

const typeWellSegmentsHandler = rest.post(
  `${PDP_SERVICE_ROOT}/api/v1/hierarchy/:job/forecast/:rescat`,
  async (req, res, ctx) => {
    const body = await req.json();
    const rescat = numberToRescatString(req.params.rescat);
    const segments = {};
    const constants = {};
    const lengths = {
      "zMcD - Vermilion Alameda MID Ridge 2": 1400
    };
    for (const uwi of body) {
      segments[uwi] = [
        {
          entityId: "zMcD - Woodcote Peavine CLWTR South",
          product: "OIL",
          parent_Id: "f5eb98d0-6003-4968-8375-03dc6fc83a6c",
          qi: 225,
          di: 0,
          diPercent: 0,
          qf: 225,
          df: 0,
          n: 0,
          b: 0,
          max: 0,
          maxRate: 0,
          start_Date: "2024-01-01T00:00:00",
          end_Date: "2024-01-23T05:20:00",
          startDate: "2024-01-01T00:00:00Z",
          endDate: "2024-01-23T05:20:00Z",
          timeCalculated: true,
          diCalculated: true,
          trendCum: 5000.000000000001,
          minDecline: 0,
          reserveCategoryId: req.params.rescat,
          rescatCode: rescat,
          segmentIndex: 0
        },
        {
          entityId: "zMcD - Woodcote Peavine CLWTR South",
          product: "OIL",
          parent_Id: "f5eb98d0-6003-4968-8375-03dc6fc83a6c",
          qi: 225,
          di: 1.48368670712854,
          diPercent: 148.368670712854,
          qf: 14.763345452084852,
          df: 0.12783337150988489,
          n: 0.9,
          b: 0.9,
          max: 0,
          maxRate: 0,
          start_Date: "2024-01-23T05:20:00",
          end_Date: "2032-01-02T08:06:43",
          startDate: "2024-01-23T05:20:00Z",
          endDate: "2032-01-02T08:06:43Z",
          timeCalculated: true,
          diCalculated: false,
          trendCum: 132072.73899577628,
          minDecline: 0,
          reserveCategoryId: req.params.rescat,
          rescatCode: rescat,
          segmentIndex: 1
        },
        {
          entityId: "zMcD - Woodcote Peavine CLWTR South",
          product: "OIL",
          parent_Id: "f5eb98d0-6003-4968-8375-03dc6fc83a6c",
          qi: 14.76334545208485,
          di: 0.12783337150988489,
          diPercent: 12.783337150988489,
          qf: 5,
          df: 0.12783337150988489,
          n: 0,
          b: 0,
          max: 0,
          maxRate: 0,
          start_Date: "2032-01-02T08:06:43",
          end_Date: "2040-06-21T19:55:30",
          startDate: "2032-01-02T08:06:43Z",
          endDate: "2040-06-21T19:55:30Z",
          timeCalculated: true,
          diCalculated: false,
          trendCum: 27895.600805673486,
          minDecline: 0,
          reserveCategoryId: req.params.rescat,
          rescatCode: rescat,
          segmentIndex: 2
        }
      ];
      constants[uwi] = [
        {
          entityId: "zMcD - Woodcote Peavine CLWTR South",
          product: "OIL_PLUS_WATER",
          value: 1.23,
          reserveCategoryId: req.params.rescat
        }
      ];
    }
    return res(
      ctx.json({
        segments,
        constants,
        lengths
      })
    );
  }
);

export const handlers = [
  jobListHandler,
  jobHierarchyHandler,
  wellInHierarchyHandler,
  typeWellLengthsHandler,
  typeWellSegmentsHandler
];
