import { Divider, Switch } from "antd";
import styled from "styled-components/macro";

import { updateXDASettings, useVisState } from "components/vis/context";

import { XdaOptionsWrapper } from "./XdaOptionsLayout";

function XdaGridOptions() {
  const [{ xda }, visDispatch] = useVisState();
  const { showRelativeDepth, showTVDSSDepth, showGrid } = xda.settings;

  const handleSettingChange = (key) => (value) => {
    const nextSettings = { ...xda.settings, [key]: value };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <XdaOptionsWrapper overflowY="none">
      <SectionHeader>X-Axis</SectionHeader>
      <OptionItem>
        <Label>Line Distance (m)</Label>
        <Switch
          size="small"
          checked={showGrid}
          onChange={handleSettingChange("showGrid")}
        />
      </OptionItem>
      <AxesDivider />
      <SectionHeader>Y-Axis</SectionHeader>
      <OptionItem>
        <Label>Relative Depth (m)</Label>
        <Switch
          size="small"
          checked={showRelativeDepth}
          onChange={handleSettingChange("showRelativeDepth")}
        />
      </OptionItem>
      <OptionItem>
        <Label>TVD SS (m)</Label>
        <Switch
          size="small"
          checked={showTVDSSDepth}
          onChange={handleSettingChange("showTVDSSDepth")}
        />
      </OptionItem>
    </XdaOptionsWrapper>
  );
}

export default XdaGridOptions;

const Label = styled.label`
  min-width: 120px;
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;

  .ant-slider {
    width: 80px;
  }

  padding: 2px 0;
`;

const AxesDivider = styled(Divider)`
  margin: 10px 0;
`;

const SectionHeader = styled.div`
  font-weight: bold;
`;
