import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { DATA_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { NetworkFraction } from "./useGetFacilityNetworkFraction";

const API_URL = `${DATA_SERVICE_ADDRESS}/api/v1/midstream/network-fractions`;

export const GetNetworkFractionKey = "network-fraction";

const fetchContributingWells = async (facilityIds: string[]) => {
  const response = await axios.post<NetworkFraction[]>(API_URL, facilityIds);
  const data = response?.data;
  if (!Array.isArray(data)) {
    return [];
  }
  return response?.data ?? [];
};

const useGetContributingWells = () => {
  return useMutation<NetworkFraction[], AxiosError, string[]>(fetchContributingWells);
};

export default useGetContributingWells;
