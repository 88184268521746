import { GridWidget, GridWidgetTemplate } from "models/dashboard";

import {
  ForecastingWidgetKey,
  GeoMapLoaderWidgetKey,
  MidstreamChartWidgetKey,
  MidstreamDataTableWidgetKey,
  PadScenarioWidgetKey,
  TypeWellDetailsWidgetKey,
  TypeWellEditorWidgetKey,
  WellDataAnalyzer,
  WellDownstreamReportWidgetKey
} from "../constants/widgets.constants";

export const getWidgetName = (widget: GridWidget | GridWidgetTemplate): string => {
  if (!widget) return "";

  switch (widget.component?.toLowerCase()) {
    case "map":
      return "Map";
    case "xda":
      return "XDA Viewer";
    case "threed":
      return "3D Visualizer";
    case "data-table":
      return "Data Table";
    case "chart":
      return "Chart";
    case "well-card":
      return "Well Card";
    case "type-log":
      return "Type Log";
    case "facility-ticket":
      return "Facility Ticket";
    case "info":
      return "Well Information";
    case "xdaviewer":
      return "XDA Viewer";
    case "survey-vis":
      return "Survey Visualizer";
    case "completion":
      return "Well Completions";
    case "sam":
      return "SAM Charts";
    case "sam-table":
      return "SAM Table";
    case "exploitable-report":
      return "Exploitable Report";
    case "sam-rf-pv":
      return "SAM RF vs PV";
    case "multiphase-chart":
      return "Multiproduct Chart";
    case TypeWellEditorWidgetKey:
      return "Typewell Editor";
    case TypeWellDetailsWidgetKey:
      return "Typewell Details";
    case GeoMapLoaderWidgetKey:
      return "Geo Map Loader";
    case MidstreamChartWidgetKey:
      return "Midstream Chart";
    case MidstreamDataTableWidgetKey:
      return "Midstream Data Table";
    case PadScenarioWidgetKey:
      return "Pad Scenario";
    case ForecastingWidgetKey:
      return "Forecasting";
    case WellDataAnalyzer:
      return "Well Data Analyzer";
    case WellDownstreamReportWidgetKey:
      return "Well Downstream Report";
    default:
      return "";
  }
};
