import { SHAPEFILE_LABELS_LAYER } from "constants/mapLayers.constants";

import { IEditableProjectLayer } from "components/project/layers/hooks";
import { Shapefile } from "components/project/shapefiles/queries";

import { getUncheckedFeatures } from "../../getUncheckedFeatures";
import { PROPERTY_SHAPEFILE_TYPE } from "../constants";

interface AddShapefilePropertyLayerParams {
  addLayer;
  layerList: IEditableProjectLayer[];
  shapefile: Shapefile;
  shapefileFeatureProperty: string;
  shapefileFeatures;
  shapefileId: string;
}

export function addShapefilePropertyLayer(params: AddShapefilePropertyLayerParams) {
  const {
    addLayer,
    layerList,
    shapefile,
    shapefileFeatureProperty,
    shapefileFeatures,
    shapefileId
  } = params;

  const geomKeys =
    shapefileFeatures?.[shapefileId]?.features
      ?.filter((f) => f.checked)
      .map((f) => f.shapefileGeomId) || [];

  const uncheckedFeatures = geomKeys?.length
    ? geomKeys
    : getUncheckedFeatures(shapefileId, shapefileFeatures, shapefile);

  const textOpacity = layerList.find((l) => l.name === SHAPEFILE_LABELS_LAYER).isChecked
    ? ["match", ["get", "shapefileGeomId"], uncheckedFeatures, 1, 0]
    : 0;

  addLayer({
    id: `${shapefileId}${PROPERTY_SHAPEFILE_TYPE}`,
    type: "symbol",
    source: shapefileId,
    layout: {
      "text-field": ["get", shapefileFeatureProperty],
      "text-size": 9,
      "text-variable-anchor": ["top"],
      visibility: layerList.find((l) => l.name === SHAPEFILE_LABELS_LAYER).isChecked
        ? "visible"
        : "none"
    },
    paint: {
      "text-color": "#000",
      "text-halo-color": "#fff",
      "text-halo-width": 2,
      "text-opacity": textOpacity
    },
    minzoom: 8,
    maxzoom: 22
  });
}
