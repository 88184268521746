import { useMutation, useQueryClient } from "react-query";

import axios from "axios";
import moment from "moment";

import { FORECASTING_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { UpdateForecastWell } from "../models/forecastWell";
import { FetchForecastWellQuery } from "./useFetchForecastWell";

const API_URL = `${FORECASTING_SERVICE_ADDRESS}/api/v1`;

const useUpdateForecastWellOnTime = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params: { uwi: string; update: UpdateForecastWell }) => {
      const data = { ...params.update };
      data.forecast.onTimeArray = data.forecast.onTimeArray.map((onTime) => {
        return {
          month: moment(onTime.month, "YYYY-MMM").toISOString(),
          value: onTime.value * 0.01
        };
      });

      const response = await axios.put(
        `${API_URL}/well/${params.uwi}/forecast/on-time`,
        data
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FetchForecastWellQuery);
      }
    }
  );
};

export default useUpdateForecastWellOnTime;
