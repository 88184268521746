import { memo, useCallback } from "react";
import { useSelector } from "react-redux";

import LaunchIcon from "@material-ui/icons/Launch";
import { RootState } from "store/rootReducer";

import { useUser } from "hooks";

import { EntityKind } from "../../../models/entityKind";
import { useChartState } from "../context";
import ToolbarButton from "./ToolbarButton";

const getPopoutUrl = (channel, entityKind) =>
  `/single/chart/${channel}?channel=${channel}&entityKind=${entityKind}`;

const Popout = () => {
  const { sessionId } = useUser();
  const { settings, entityKind } = useChartState();
  const channel = useSelector((state: RootState) => state.channel.channel);
  const filterId = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.filterId : state.facilityFilter.filterId
  );
  const groupBy = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.groupBy.globalGroupBy
      : state.groupBy.globalFacilityFocus
  );
  const activeColorPalette = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.userSetting.activeColorPalette
      : state.userSetting.activeFacilityColorPalette
  );
  const checkedGlobalTypeWells = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.arps.checkedGlobalTypeWells : []
  );

  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );

  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const popout = useCallback(() => {
    sessionStorage.removeItem(channel);
    const globalGroupBy = { ...groupBy };

    const data = JSON.stringify({
      filterId: filterId,
      globalGroupBy,
      checkedGlobalTypeWells,
      settings,
      entityKind,
      sessionId,
      activeColorPalette,
      globalNormalizeBy,
      useNormalizeBy
    });

    sessionStorage.setItem(channel, data);
    window.open(getPopoutUrl(channel, entityKind));
  }, [
    channel,
    filterId,
    groupBy,
    settings,
    settings.legend.position,
    settings.legend.visible,
    activeColorPalette,
    globalNormalizeBy,
    useNormalizeBy
  ]);

  return (
    <ToolbarButton
      active={false}
      icon={<LaunchIcon fontSize="large" />}
      overflowLabel="Pop out"
      tooltipText="Open in new Tab"
      onToggle={() => popout()}
    />
  );
};

export default memo(Popout);
