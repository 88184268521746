import { useState } from "react";

import { Popover } from "antd";

import { GroupBy as GroupByIcon } from "components/icons";

import { useChartState } from "../../context";
import ToolbarButton from "../ToolbarButton";
import ChartFocus from "./ChartFocus";

const FocusToggle = () => {
  const { settings } = useChartState();
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      placement="bottomRight"
      arrowPointAtCenter
      content={<ChartFocus />}
      trigger="click"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        icon={<GroupByIcon size={18} />}
        isMenuButton={true}
        overflowLabel="Chart Focus"
        tooltipText="Chart Focus"
        active={visible || settings.useChartFocus}
        onToggle={(v) => setVisible(v)}
      />
    </Popover>
  );
};

export default FocusToggle;
