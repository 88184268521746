import { memo, useState } from "react";

import { Popover } from "antd";

import { LegendFilled } from "components/icons";

import ToolbarButton from "../../../toolbar/ToolbarButton";
import Legend from "./Legend";

const LegendToggle = () => {
  const [visible, setVisible] = useState(false);
  const onToggleHandle = (value) => {
    setVisible(value);
  };
  return (
    <Popover
      arrowPointAtCenter
      content={<Legend />}
      placement="bottomRight"
      trigger="click"
      open={visible}
      onOpenChange={onToggleHandle}>
      <ToolbarButton
        testId="multiphasechart-toolbar-legend-toggle"
        active={visible}
        icon={<LegendFilled style={{ width: "30px", height: "30px" }} />}
        isMenuButton={true}
        overflowLabel="Legend"
        tooltipText="Legend"
        onToggle={onToggleHandle}
      />
    </Popover>
  );
};

export default memo(LegendToggle);
