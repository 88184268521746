import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

export default function useDefaultTypeWell() {
  const typeWellSettings = useSelector(
    (state: RootState) => state.userSetting.typeWellSettings
  );

  const getDefaultTypeWell = (product) => {
    return typeWellSettings.settings.find(
      (s) => s.product === product || (s.product == "CONDENSATE" && product == "COND.")
    );
  };

  return { getDefaultTypeWell };
}
