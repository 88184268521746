import { useDispatch } from "react-redux";

import { setSelectedEntities } from "store/features";

import { EntityKind } from "../../../models/entityKind";

const useSelectedWellFromStoreUpdater = (entityKind: EntityKind) => {
  const dispatch = useDispatch();

  const updateSelectedWell = (uwi: string) => {
    const obj = {};
    obj[uwi] = { Uwi: uwi };
    dispatch(setSelectedEntities(entityKind, obj));
  };

  return { updateSelectedWell };
};

export default useSelectedWellFromStoreUpdater;
