// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiRadiusOutline } from "@mdi/js";
import { Popover } from "antd";

import XdaRadiusOptions from "./XdaRadiusOptions";
import XdaToolbarButton from "./XdaToolbarButton";

function XdaRadiusToggle() {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={<XdaRadiusOptions />}
      open={visible}
      onOpenChange={(v) => setVisible(v)}
      trigger="click"
      placement="bottomRight">
      <XdaToolbarButton
        icon={<Icon path={mdiRadiusOutline} size={1.5} />}
        isMenuButton={true}
        active={false}
        overflowLabel={"Radius"}
        tooltipText={"Radius Options"}
        onToggle={(v) => setVisible(v)}
      />
    </Popover>
  );
}

export default XdaRadiusToggle;
