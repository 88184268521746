import { useEffect, useRef } from "react";

import {
  FORECAST_KEY,
  MY_FOCUS_FIELDS,
  ORG_FOCUS_FIELDS,
  ORG_GEO_FIELDS,
  SYNC_KEY
} from "constants/settings.constants";
import { getProductType } from "utils";

import { IGroupByListItem } from "models";

import { PropsT, useGroupByState, useGroupByUpdater } from "../group-by.context";

/**
 * Uses the prop value to set selected-category and selected-product in context
 */
const useProps = (props: PropsT): void => {
  const { chartType, dataTypeFilters, editable, value } = props;
  const { allColumns, categoryList, selectedCategory, selectedField } = useGroupByState();
  const updateGroupByState = useGroupByUpdater();
  const selectedCategoryNameRef = useRef<string>();

  useEffect(() => {
    selectedCategoryNameRef.current = selectedCategory?.name;
    return () => {
      selectedCategoryNameRef.current = null;
    };
  }, [selectedCategory]);

  useEffect(() => {
    const isPlusForecast = value.property.endsWith(`.${FORECAST_KEY}`);
    if (!isPlusForecast) return;
    updateGroupByState({ type: "forecast_toggle", payload: isPlusForecast });
  }, [updateGroupByState, value.property]);

  // save props to context
  useEffect(() => {
    updateGroupByState({ type: "props", payload: props });
  }, []);

  useEffect(() => {
    updateGroupByState({ type: "props", payload: props });
  }, [editable, dataTypeFilters, chartType]);

  useEffect(() => {
    //when users updated the property this will update the correct selected
    //category ref
    const categoryName = value?.property ? getCategoryName(value.property) : "";
    if (selectedCategoryNameRef.current !== categoryName && categoryList) {
      const category = (categoryList ?? []).find(
        ({ name }) => name === categoryName || categoryName.startsWith(name)
      );

      if (category) {
        selectedCategoryNameRef.current = category?.name;
      }
    }
  }, [value]);

  function getCategoryName(property: string): string {
    return property.split(".").find(Boolean).split("_").join(" ").replace("RandD", "R&D");
  }

  useEffect(() => {
    if (!value?.title) return;
    if (!categoryList?.length) return;
    const { property } = value;
    if (!property) {
      return;
    }

    // update selected-field
    const field = allColumns.find((column) => column.property === property);

    const categoryName = field?.group ?? getCategoryName(property);

    let category: IGroupByListItem;

    // This case to cover update (add/delete) items from Shapefile, My Fields and Organization Fields
    // We would refer to reload the Category List into the tab with updated data
    if (
      selectedCategoryNameRef.current === "Shapefile" ||
      selectedCategoryNameRef.current === MY_FOCUS_FIELDS ||
      selectedCategoryNameRef.current === ORG_FOCUS_FIELDS ||
      selectedCategoryNameRef.current === ORG_GEO_FIELDS
    ) {
      category = categoryList.find(
        ({ name }) => name === selectedCategoryNameRef.current
      );
      if (selectedCategoryNameRef.current !== categoryName) {
        updateGroupByState({ type: "selected_category", payload: category });
        if (field != selectedField) {
          updateGroupByState({ type: "selected_field", payload: field });
        }
        return;
      }
    } else {
      category = categoryList.find(({ name }) => name === categoryName);
    }

    // TODO BF: some notes, overall this useEffect is confusing and has a lot of side effects
    // Consider investigating change, it is unclear why the selected category is being updated here, but it could have unexpected side effects
    // from how this category name is retrieved (by extracting the category from the selected property).
    // It seems like we're using the value property to 'override' the category in scenarios, may not be needed after refactor to column groups
    // If over riding is still neccessary, we could probably just put this in the column selection event
    // Unless this was initially covering application load where we need to set the selected category based on the initial property, if so we should document it
    if (category?.name) {
      //update when the categories are the same
      updateGroupByState({ type: "selected_category", payload: category });
    }

    // update selected-product, if category is "Performance" or "Forecast"
    const numberOfDots = property.split(".").length - 1;

    const hasProductNameInProperty =
      numberOfDots > 1 && property.split(".")[0] !== "QC_Meta" && !property.includes(`.${SYNC_KEY}.`);

    if (hasProductNameInProperty) {
      const productLabel = property.split(".")[1];
      const product = getProductType(productLabel);

      updateGroupByState({ type: "selected_product", payload: product.label });
    }

    if (field) {
      updateGroupByState({ type: "selected_field", payload: field });
    }
  }, [categoryList, value.property]);
};

export default useProps;
