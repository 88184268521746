// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import useResizeObserver from "@react-hook/resize-observer";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { mdiCheck, mdiClipboard } from "@mdi/js";
import { Button, DatePicker, Table, TableProps, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { EntityKind } from "models/entityKind";

import { IconSpinner } from "components/icons";
import { NetworkFraction } from "components/map/hooks/useGetFacilityNetworkFraction";
import { ButtonIconCentered } from "components/shared";
import { useWellList } from "components/well-list/context";

import useFetchWellDownstreamReport from "./hooks/useFetchWellDownstreamReport";

const Title = Typography.Title;

export const WellDownstreamReport: FC = () => {
  const activeEntityKinds = useSelector(
    (state: RootState) => state.app.activeEntityKinds
  );
  const [copied, setCopied] = useState(false);
  const well = useSelector((state: RootState) => state.map.selectedWells);
  const [wellListState] = useWellList();
  const containerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const [scroll, setScroll] = useState({ y: 100, x: 200 });
  const [selectedMonth, setSelectedMonth] = useState<string>(null);
  const [wellsToDisplay, setWellsToDisplay] = useState<string[]>([]);
  const [wellsToLoad, setWellsToLoad] = useState<string[]>([]);
  const { data, isFetching } = useFetchWellDownstreamReport({
    month: selectedMonth,
    ids: wellsToLoad?.map((w) => w) ?? []
  });
  useEffect(() => {
    if (well) {
      setWellsToLoad(Object.keys(well));
    }
  }, [well]);

  useEffect(() => {
    if (data?.wells) {
      setWellsToDisplay(data.wells);
    }
    if (data?.month) {
      setSelectedMonth(data.month);
    }
  }, [data]);

  const columns: TableProps<NetworkFraction>["columns"] = [
    {
      title: "UWID",
      dataIndex: "wellId",
      key: "wellId",
      width: 190
    },
    {
      title: "Destination Facility",
      dataIndex: "destinationFacility",
      key: "destinationFacility",
      width: 120
    },
    {
      title: "Depth",
      dataIndex: "depth",
      key: "depth",
      width: 60
    },

    {
      title: "Contribution Fraction",
      dataIndex: "fraction",
      key: "fraction",
      width: 120
    },
    {
      title: "Month",
      dataIndex: "productionMonth",
      key: "productionMonth",
      width: 80
    }
  ];
  const copyTableToClipboard = async () => {
    if (!navigator.clipboard) {
      toast.error("Clipboard copy is not supported by the browser.");
    }
    if (!tableRef.current) {
      toast.error("Table is not available.");
    }
    const spreadSheetRow = new Blob([tableRef.current.outerHTML], {
      type: "text/html"
    });
    try {
      await navigator.clipboard.write([
        new ClipboardItem({ "text/html": spreadSheetRow })
      ]);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      toast.error("Failed to copy to clipboard.");
    }
  };
  useResizeObserver(containerRef, (client) => {
    if (!client) {
      return;
    }
    setScroll({ y: client.contentRect.height - 100, x: client.contentRect.width - 270 });
  });

  const handleLoadWellsFromList = () => {
    if (wellListState.wells.length > 100) {
      toast.warn("Only the first 100 wells are loaded");
    }
    setWellsToLoad(wellListState.wells.slice(0, 100).map((w) => w.label));
  };
  if (!activeEntityKinds.includes(EntityKind.Facility)) {
    return (
      <DownstreamReportRootContainer ref={containerRef}>
        <Title level={4}>Facility is disabled. Enable facility to view report.</Title>
      </DownstreamReportRootContainer>
    );
  }

  return (
    <DownstreamReportRootContainer ref={containerRef}>
      <TitleWrapper>
        <Title level={4}>Well Downstream Report {isFetching && <IconSpinner />}</Title>
        <ButtonIconCentered
          type="primary"
          onClick={copyTableToClipboard}
          icon={
            copied ? (
              <Icon path={mdiCheck} size={1.0} />
            ) : (
              <Icon path={mdiClipboard} size={1.0} />
            )
          }>
          Copy to Clipboard
        </ButtonIconCentered>
      </TitleWrapper>
      <ContentContainer>
        <ReportOptions
          wellsToDisplay={wellsToDisplay}
          onLoadWellsFromList={handleLoadWellsFromList}
          month={selectedMonth}
          onWellsChange={(wells) => setWellsToLoad(wells)}
          onMonthChange={(month) => setSelectedMonth(month)}
        />

        <Table
          ref={tableRef}
          locale={{
            emptyText: "Please select a well or load from well list to get started."
          }}
          dataSource={data?.network}
          size="small"
          scroll={scroll}
          columns={columns}
          pagination={false}
        />
      </ContentContainer>
    </DownstreamReportRootContainer>
  );
};

interface OptionsProps {
  month?: string;
  onMonthChange?: (month: string) => void;
  wellsToDisplay: string[];
  onLoadWellsFromList?: () => void;
  onWellsChange?: (wells: string[]) => void;
}

const ReportOptions: FC<OptionsProps> = ({
  month,
  onMonthChange,
  onLoadWellsFromList,
  wellsToDisplay,
  onWellsChange
}) => {
  const [wellsString, setWellsString] = useState<string>(wellsToDisplay.join("\n"));
  useEffect(() => {
    if (!wellsToDisplay) {
      setWellsString("");
      return;
    }
    setWellsString(wellsToDisplay.join("\n"));
  }, [wellsToDisplay]);
  return (
    <ReportOptionsContainer>
      <Title level={5}>Options</Title>
      <label>Month</label>
      <DatePicker
        picker="month"
        value={month ? moment(month) : undefined}
        onChange={(date) => {
          if (!date) {
            return;
          }
          const dateString = date.format("YYYY-MM-DD");
          onMonthChange(dateString);
        }}
      />
      <label>Wells</label>
      <TextArea
        value={wellsString}
        onChange={(e) => setWellsString(e.target.value)}></TextArea>
      <Button
        onClick={() => {
          onWellsChange &&
            onWellsChange(wellsString.split("\n").filter((w) => w.length > 0));
        }}>
        Update
      </Button>

      <Button onClick={onLoadWellsFromList}>Load Wells From Well List</Button>
    </ReportOptionsContainer>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  .ant-typography {
    height: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    overflow: hidden;
    padding: 5px;
  }
`;

const ReportOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;
  height: 100%;
  min-width: 220px;
  gap: 5px;
  textarea {
    flex-grow: 1;
    height: 100%;
    font-family: var(--fontMono);
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  .ant-table-wrapper {
    border: 1px solid lightgray;
    min-width: 500px;
  }
  overflow-x: auto;
`;

const DownstreamReportRootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
`;
