import { useCallback, useMemo, useRef, useState } from "react";

// Command object representing an action
export class Command {
  public execute: () => void;
  public undo: () => void;

  constructor(execute, undo) {
    this.execute = execute;
    this.undo = undo;
  }
}

export interface IUndoRedoManager {
  executeCommand: (Command) => void;
  undo: () => void;
  redo: () => void;
  reset: () => void;
  canUndo: boolean;
  canRedo: boolean;
}

// Custom hook for undo/redo functionality
const useUndoRedo = (): IUndoRedoManager => {
  const [currentState, setCurrentState] = useState<number>(0);
  const currentStateRef = useRef<number>(0);

  const [commands, setCommands] = useState<Command[]>([]);

  // Function to execute a command
  const executeCommand = useCallback(
    (command: Command) => {
      command.execute();

      // Store the command in history
      const newCommands = [...commands.slice(0, currentStateRef.current), command];
      setCommands(newCommands);
      currentStateRef.current += 1;
      setCurrentState(currentStateRef.current);
    },
    [commands]
  );

  // Function to handle undo action
  const undo = () => {
    if (currentStateRef.current <= commands.length && currentStateRef.current > 0) {
      currentStateRef.current -= 1;
      setCurrentState(currentStateRef.current);
      commands[currentStateRef.current].undo();
    }
  };

  const reset = () => {
    setCurrentState(0);
    currentStateRef.current = 0;
    setCommands([]);
  };

  // Function to handle redo action
  const redo = () => {
    if (currentStateRef.current < commands.length) {
      commands[currentStateRef.current].execute();
      currentStateRef.current += 1;
      setCurrentState(currentStateRef.current);
    }
  };

  return useMemo(
    () => ({
      executeCommand,
      undo,
      redo,
      reset,
      canUndo: currentState > 0,
      canRedo: currentState < commands.length
    }),
    [executeCommand, currentState, commands.length]
  );
};

export default useUndoRedo;
