import { useCallback, useEffect, useState } from "react";

import { Collapse, Input, Select, Switch } from "antd";
import _debounce from "lodash/debounce";

import usePlayTo3DDataSources from "hooks/usePlayTo3DDataSources";
import usePlayTo3DFields from "hooks/usePlayTo3DFields";
import useUserModules from "hooks/useUserModules";

import { ThreeDViewerOptionT } from "models";

import { useVisState } from "../../context";
import {
  ThreeDVOptionItem,
  ThreeDVOptionLabel,
  ThreeDVOptionsWrapper
} from "./ThreeDVOptionsLayout";

const { Option } = Select;
const { Panel } = Collapse;

const env = process.env.REACT_APP_ENV;

function ThreeDVGeoValueOptions(props: ThreeDViewerOptionT): JSX.Element {
  const [colorMapMin, setColorMapMin] = useState<string>(
    props.colorMapMin?.toString() ?? ""
  );
  const [colorMapMax, setColorMapMax] = useState<string>(
    props.colorMapMax?.toString() ?? ""
  );

  const { has3dGeoModel } = useUserModules();

  const [{ xda }] = useVisState();
  const { ipdbSource: xdaIpdbSource, ipdbField: xdaField } = xda.settings;
  const { data: dataSources, refetch: refetchDataSources } = usePlayTo3DDataSources();
  const [ipdbField, setIpdbField] = useState(xdaField);
  const [ipdbSource, setIpdbSource] = useState(xdaIpdbSource);
  const { data: fields, refetch } = usePlayTo3DFields(ipdbSource);
  useEffect(() => {
    refetch();
    refetchDataSources();
  }, []);

  const debouncedOnChange = useCallback(
    _debounce((val, refresh) => {
      props.onOptionChange && props.onOptionChange(val, refresh);
    }, 500),
    [props.onOptionChange]
  );

  useEffect(() => {
    const ipdbFieldChanged = xda.settings.ipdbField != ipdbField;
    const ipdbSourceChanged = xda.settings.ipdbSource != ipdbSource;
    if (ipdbFieldChanged && ipdbSourceChanged) {
      setIpdbField(xda.settings.ipdbField);
      setIpdbSource(xda.settings.ipdbSource);
      debouncedOnChange(
        { ipdbField: xda.settings.ipdbField, ipdbSource: xda.settings.ipdbSource },
        true
      );
    } else if (ipdbFieldChanged) {
      setIpdbField(ipdbField);
      debouncedOnChange({ ipdbField: ipdbField }, false);
    } else if (ipdbSourceChanged) {
      setIpdbSource(ipdbSource);
      debouncedOnChange({ ipdbSource: ipdbSource }, true);
    }
  }, [xda.settings.ipdbField, xda.settings.ipdbSource]);

  return (
    <ThreeDVOptionsWrapper>
      {(env === "mcdan" || has3dGeoModel) && (
        <>
          <ThreeDVOptionItem>
            <label>Show Well Volume</label>
            <Switch
              className="action"
              size="small"
              checked={props.showDrainage}
              onChange={(e) => {
                props.onOptionChange && props.onOptionChange({ showDrainage: e }, true);
              }}
            />
          </ThreeDVOptionItem>
          <Collapse accordion>
            <Panel
              header="Well Geo Values"
              key="IPDB"
              collapsible={props.showDrainage ? "header" : "disabled"}
              extra={
                <Switch
                  className="action"
                  disabled={!props.showDrainage}
                  checked={props.showDrainage && props.showIPDBDrainage}
                  onChange={(e) => {
                    props.onOptionChange &&
                      props.onOptionChange({ showIPDBDrainage: e }, true);
                  }}
                />
              }>
              <ThreeDVOptionItem>
                <label>Model Source</label>
                <Select
                  size="small"
                  value={ipdbSource}
                  options={dataSources?.map((source) => ({
                    label: source,
                    value: source
                  }))}
                  onChange={(source) => {
                    setIpdbSource(source);
                    props.onOptionChange({ ipdbSource: source }, true);
                  }}
                />
              </ThreeDVOptionItem>

              <ThreeDVOptionItem>
                <ThreeDVOptionLabel>Field</ThreeDVOptionLabel>
                <Select
                  size="small"
                  value={ipdbField}
                  options={fields?.map((field) => ({
                    label: field.displayName,
                    value: field.displayName,
                    title: field.tooltip ?? field.displayName
                  }))}
                  onChange={(value) => {
                    setIpdbField(value);
                    const field = fields?.find((f) => f.displayName === value);
                    props.onOptionChange &&
                      props.onOptionChange(
                        {
                          ipdbField: value,
                          ipdbFieldUnit: field?.unit ?? ""
                        },
                        false
                      );
                  }}
                />
              </ThreeDVOptionItem>

              <ThreeDVOptionItem>
                <label>Color</label>
                <Select
                  value={props.colorMap}
                  onChange={(e) => {
                    props.onOptionChange && props.onOptionChange({ colorMap: e }, false);
                  }}>
                  <Option value="rainbow" key="rainbow">
                    Rainbow
                  </Option>
                  <Option value="cooltowarm" key="cooltowarm">
                    Cool to Warm
                  </Option>
                  <Option value="blackbody" key="blackbody">
                    Blackbody
                  </Option>
                </Select>
              </ThreeDVOptionItem>

              <ThreeDVOptionItem>
                <label>Min</label>
                <Input
                  value={colorMapMin}
                  onChange={(e) => {
                    setColorMapMin(e.target.value);
                    debouncedOnChange(
                      {
                        colorMapMin: e.target.valueAsNumber
                      },
                      false
                    );
                  }}
                  type="number"
                />
              </ThreeDVOptionItem>

              <ThreeDVOptionItem>
                <label>Max</label>
                <Input
                  value={colorMapMax}
                  onChange={(e) => {
                    setColorMapMax(e.target.value);
                    debouncedOnChange(
                      {
                        colorMapMax: e.target.valueAsNumber
                      },
                      false
                    );
                  }}
                  type="number"
                />
              </ThreeDVOptionItem>
            </Panel>
          </Collapse>
        </>
      )}
    </ThreeDVOptionsWrapper>
  );
}

export default ThreeDVGeoValueOptions;
