import { useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { Uwi } from "models";

import { EntityKind } from "../../../models/entityKind";
import { SelectedWellT, useWellList } from "../context/WellListContext";
import { updateSelectedWell } from "../context/reducer";

const useSelectedEntitiesFromStore = (entityKind: EntityKind) => {
  const selectedEntities = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.map.selectedWells : state.map.selectedFacilities
  );
  const [, dispatch] = useWellList();

  useEffect(() => {
    if (!selectedEntities) return;
    const selectedWell: SelectedWellT =
      Object.keys(selectedEntities).length > 0
        ? { ...(Object.values(selectedEntities)[0] as SelectedWellT) }
        : null;

    if (selectedWell?.Uwi && !selectedWell?.FormattedUwi) {
      selectedWell.FormattedUwi = new Uwi().toFormatted(selectedWell?.Uwi);
    }

    updateSelectedWell(dispatch, selectedWell);
  }, [dispatch, selectedEntities]);
};

export default useSelectedEntitiesFromStore;
