import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "antd";
import { setDataSourceSetting } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { PdenSourceT } from "models/pdenDataSourceSetting";

import { DataSourceIcon, DataSourceSelect } from "components/shared";

const GlobalDataSourceSelect = () => {
  const dispatch = useDispatch();
  const syncState = useSelector((state: RootState) => state.app.syncWells);

  // state from store
  const dataSourceSetting = useSelector(
    (state: RootState) => state.app.pdenDataSourceSetting
  );

  // derived state
  const isHybrid = dataSourceSetting.source === "Hybrid";

  // set to hybrid when sync is on
  useEffect(() => {
    if (!syncState?.isSync || isHybrid || syncState?.syncRequestOrigin !== "Focus") {
      return;
    }

    const nextSourceSetting = {
      ...dataSourceSetting,
      source: "Hybrid" as PdenSourceT
    };
    dispatch(setDataSourceSetting(nextSourceSetting));
  }, [syncState]);

  function handleSourceChange(value: PdenSourceT): void {
    const nextSourceSetting = {
      ...dataSourceSetting,
      source: value
    };
    dispatch(setDataSourceSetting(nextSourceSetting));
  }

  return (
    <DataSourceSelect
      trigger={["click"]}
      value={dataSourceSetting.source}
      onChange={handleSourceChange}>
      <StyledButton icon={DataSourceIcon[dataSourceSetting.source]} />
    </DataSourceSelect>
  );
};

const StyledButton = styled(Button)`
  background: transparent;
  color: #a2aaad;
  &:hover {
    color: var(--color-text);
  }
`;

export default GlobalDataSourceSelect;
