import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { TEMPLATES } from "api/userArps";

import { SegmentTemplate, TypeWellTemplate } from "../models/SegmentTemplate";
import useSegmentTemplates from "./useSegmentTemplates";

export function useTypeWellTemplates(): SegmentTemplate[] {
  const segmentTemplates = useSegmentTemplates();

  const { loading, data } = useQuery(TEMPLATES);

  return useMemo(() => {
    if (loading) return [];

    if (data) {
      return data.templates
        .map((template: TypeWellTemplate) => {
          const segment = segmentTemplates.find(
            (segment: SegmentTemplate) => segment.type === template.type
          );
          if (!segment) return null;

          return {
            ...segment,
            name: template.name,
            numberOfSegments: template.numberOfSegments,
            rowHeaders: template.headers.map((h) => h.name),
            displayHeaders: template.headers.map((h) => h.displayName),
            update: segment.update,
            initialData: segment.initialData,
            extendArps: segment.extendArps
          } as SegmentTemplate;
        })
        .filter((t) => t !== null) as SegmentTemplate[];
    }

    return [];
  }, [loading, data, segmentTemplates]);
}
