/* eslint-disable no-console */
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import { SHAPEFILE_NAME_LABELS_LAYER } from "constants/mapLayers.constants";
import { RootState } from "store/rootReducer";

import { useEditableProjectLayerList } from "components/project/layers/hooks";
import { useProjectContext } from "components/project/projects/context";
import { useCheckedShapefileList } from "components/project/shapefiles/hooks";

import { useMapContext } from "../useMapContext";
import { useMapDispatch } from "../useMapDispatch";
import {
  LABEL_SHAPEFILE_TYPE,
  onShapefilePropertyChanged,
  onShapefilesChanged
} from "./utils";

export interface IUseManageMapboxShapefileLoading {
  addLayer;
  removeLayer;
}
export default function useManageMapboxShapefileLoading({
  addLayer
}: IUseManageMapboxShapefileLoading) {
  // Global state
  const shapefileFeatures = useSelector(
    (state: RootState) => state.project.shapefileFeatures
  );

  const currentShapefileFeatureProperty = useSelector(
    (state: RootState) => state.project.currentShapefileFeatureProperty
  );
  const currentShapefileIdForFeatureProperty = useSelector(
    (state: RootState) => state.project.currentShapefileIdForFeatureProperty
  );

  // Context
  const { selectedProjectId, checkedLayerKeys } = useProjectContext();

  const { mapbox, isMapInitialized, isStyleLoaded } = useMapContext();
  const mapDispatch = useMapDispatch();

  // Hooks
  const layerList = useEditableProjectLayerList({ projectId: selectedProjectId });
  const shapefileList = useCheckedShapefileList();

  // Refs
  /**
   * Used to compare to the last state of the shapefiles to remove layers that are no longer checked
   * e.g Switching between projects, or checking shapefiles
   */
  const lastCheckedShapefiles = useRef<Set<string>>();

  const lastShapefileIdForFeatureProperty = useRef<string>();
  const lastShapefileFeatureProperty = useRef<string>();

  const shapefileListSorted = useMemo(() => {
    const checkedShapefiles = Array.from(layerList.checkedShapefiles);
    const sortedShapefileList = shapefileList.data?.sort((a, b) => {
      const aId = a.projectShapefileId || a.shapefileId;
      const bId = b[aId === a.projectShapefileId ? "projectShapefileId" : "shapefileId"];
      const aIndex = checkedShapefiles.indexOf(aId);
      const bIndex = checkedShapefiles.indexOf(bId);
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

    return sortedShapefileList || [];
  }, [shapefileList.data, layerList.checkedShapefiles]);

  const GetTextOpacity = (shapefileId: string, checkedLayerKeys: string[]) => {
    const hasFeatures =
      Object.keys(shapefileFeatures).length > 0 &&
      shapefileFeatures[shapefileId]?.features?.length > 0;

    if (!hasFeatures) {
      return checkedLayerKeys.includes(SHAPEFILE_NAME_LABELS_LAYER) ? 1 : 0;
    }

    let uncheckedFeatures = shapefileFeatures[shapefileId]?.features
      .filter((f) => f.checked)
      .map((f) => f.shapefileGeomId);

    if (!uncheckedFeatures?.length) {
      uncheckedFeatures = [""];
    }

    return checkedLayerKeys.includes(SHAPEFILE_NAME_LABELS_LAYER)
      ? ["match", ["get", "shapefileGeomId"], uncheckedFeatures, 1, 0]
      : 0;
  };
  useEffect(() => {
    if (!mapbox || !isStyleLoaded || !isMapInitialized) {
      return;
    }

    onShapefilesChanged({
      addLayer,
      checkedShapefiles: layerList.checkedShapefiles,
      lastCheckedShapefiles,
      layerList: layerList.data,
      mapbox,
      shapefileFeatures,
      shapefileListSorted
    });

    onShapefilePropertyChanged({
      addLayer,
      currentShapefileFeatureProperty,
      currentShapefileIdForFeatureProperty,
      lastShapefileFeatureProperty,
      lastShapefileIdForFeatureProperty,
      layerList: layerList.data,
      mapbox,
      shapefileFeatures,
      shapefileListSorted
    });
  }, [
    mapbox,
    isStyleLoaded,
    isMapInitialized,
    layerList.checkedShapefiles,
    layerList.data,
    shapefileFeatures,
    shapefileListSorted,
    currentShapefileFeatureProperty,
    currentShapefileIdForFeatureProperty
  ]);

  useEffect(() => {
    if (!mapbox || !isStyleLoaded || !isMapInitialized || !mapbox.getStyle()?.layers) {
      return;
    }

    const mapboxLayers = mapbox.getStyle().layers;
    for (const layer of mapboxLayers) {
      if (layer.id.includes(LABEL_SHAPEFILE_TYPE)) {
        const shapefileId = layer.id.replace(LABEL_SHAPEFILE_TYPE, "");

        const textOpacity = GetTextOpacity(shapefileId, checkedLayerKeys);
        mapbox.setPaintProperty(layer.id, "text-opacity", textOpacity);
      }
    }
  }, [mapbox, isStyleLoaded, isMapInitialized, checkedLayerKeys]);

  /** Sync up shapefile list fetching with map loading.
   * This re-triggers sorting, as layer sorting is dependant on mapContext.isLoading
   * */
  useEffect(() => {
    mapDispatch({
      payload: {
        isLoading: shapefileList.isFetching || shapefileList.isLoading
      }
    });
  }, [shapefileList.isFetching, shapefileList.isLoading]);

  useEffect(() => {
    mapDispatch({
      payload: {
        mapAlert: shapefileList.isError
          ? "Unable to load shapefiles. Please try again at a later time."
          : ""
      }
    });
  }, [shapefileList.isError, mapDispatch]);
}
