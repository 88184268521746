import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setMapState } from "store/features";
import {
  setActiveColorPalette,
  setActiveFacilityColorPalette,
  setIsDefaultSettingsLoaded
} from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";

import { useProjectListQuery } from "components/project/projects/queries";
import { useGetFavouriteColumns } from "components/user-settings/hooks";
import useRefreshPreferredColorsInStore from "components/user-settings/hooks/refreshPreferredColorsInStore";
import { DEFAULT_ZOOM } from "components/user-settings/models";

import { useUserDefaults, useUserSettings } from ".";
import { EntityKind } from "../../../models/entityKind";
import { DEFAULT_USER_ABBREVIATIONS } from "../constants/userAbbreviations";
import { UserSettingsT, useUserContext } from "../context";
import {
  updateSettingFocus,
  updateSettingProject,
  updateSettings,
  updateUser
} from "../context/reducer";
import { updateSettingFacilityFocus } from "../context/reducer/UserReducer";
import { useUserSettingsDefaultsQuery } from "../queries";

const useInitialUserContextLoader = () => {
  // hooks
  const [, dispatch] = useUserContext();
  const settings = useUserSettings();
  const defaults = useUserDefaults();
  const refreshPreferredColorsInStore = useRefreshPreferredColorsInStore();

  // dispatches
  const storeDispatch = useDispatch();

  // selectors
  const user = useSelector((state: RootState) => state.auth.user);
  const colorPalettes = useSelector(
    (state: RootState) => state.userSetting.colorPalettes
  );

  // state
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);

  /**
   * Because we use this in the AppRouter, the queries in the hook will get called on the sign in page
   * before a user is signed in. It'll just create 401s until the user is signed in.
   * */
  const isAutoFetching = !!user;

  const handleSettingsLoaded = useCallback(
    (loadedUserSettings) => {
      if (!loadedUserSettings) return;

      const getSingleObjectSetting = (object, defaultObject = undefined) => {
        return Object.values(object ?? {})?.[0] ?? defaultObject;
      };
      const newSettings = {
        ...settings,
        defaults: {
          mapSettings: getSingleObjectSetting(
            loadedUserSettings.MapCenter,
            settings.defaults.mapSettings
          ),
          focusTiles: getSingleObjectSetting(
            loadedUserSettings.FocusTile,
            settings.defaults.focusTiles
          ),
          facilityFocusTiles: getSingleObjectSetting(
            loadedUserSettings.FacilityFocusTile,
            settings.defaults.facilityFocusTiles
          ),
          project: getSingleObjectSetting(
            loadedUserSettings.Project,
            settings.defaults.project
          ),
          filter: getSingleObjectSetting(
            loadedUserSettings.Filter,
            settings.defaults.filter
          ),
          facilityFilter: getSingleObjectSetting(
            loadedUserSettings.FacilityFilter,
            settings.defaults.facilityFilter
          ),
          colorPalette: getSingleObjectSetting(
            loadedUserSettings.ColorPalette,
            settings.defaults.colorPalette
          ),
          facilityPalette: getSingleObjectSetting(
            loadedUserSettings.FacilityColorPalette,
            settings.defaults.facilityPalette
          ),
          typeWellDashboard: getSingleObjectSetting(
            loadedUserSettings.TypeWellDashboard,
            settings.defaults.typeWellDashboard
          ),
          binSettings: getSingleObjectSetting(
            loadedUserSettings.BinSettings,
            settings.defaults.binSettings
          )
        },
        forecastExport: getSingleObjectSetting(
          loadedUserSettings.ExportForecastPreferences,
          settings.forecastExport
        ),
        userDocSettings: getSingleObjectSetting(
          loadedUserSettings.UserDocModel,
          settings.userDocSettings
        ),
        declineType: getSingleObjectSetting(
          loadedUserSettings.DeclineType,
          settings.declineType
        ),
        midstreamChartBackgroundColour: getSingleObjectSetting(
          loadedUserSettings.MidstreamChartBackgroundColour,
          settings.midstreamChartBackgroundColour
        ),
        userDashboardSettings: getSingleObjectSetting(
          loadedUserSettings.DashboardPreferences,
          settings.userDashboardSettings
        ),
        midstreamSettings: getSingleObjectSetting(
          loadedUserSettings.MidstreamSettings,
          settings.midstreamSettings
        ),
        geoModelSettings: getSingleObjectSetting(
          loadedUserSettings.GeoModelSettings,
          settings.geoModelSettings
        ),
        screenshotPresets: loadedUserSettings.ScreenshotPreset,
        userAbbreviations: getSingleObjectSetting(loadedUserSettings.UserAbbreviations)
      } as UserSettingsT;

      newSettings.userAbbreviations = {
        id: newSettings.userAbbreviations?.id,
        abbreviations: [
          ...DEFAULT_USER_ABBREVIATIONS,
          ...(newSettings.userAbbreviations?.abbreviations ?? [])
        ]
      };

      updateSettings(dispatch, newSettings);
      setSettingsLoaded(true);
    },
    [settings]
  );

  const userSettingsDefaultsQuery = useUserSettingsDefaultsQuery({
    onSuccess: handleSettingsLoaded,
    isAutoFetching: isAutoFetching
  });

  const handleFavouriteColumnsLoaded = (columns) => {
    updateSettingFocus(dispatch, {
      tiles: columns.map((c) => c.property)
    });
  };
  const handleFacilityFavouriteColumnsLoaded = (columns) => {
    updateSettingFacilityFocus(dispatch, {
      tiles: columns.map((c) => c.property)
    });
  };
  const { mutate: getFavouriteColumnsMutate } = useGetFavouriteColumns(
    EntityKind.Well,
    handleFavouriteColumnsLoaded
  );
  const { mutate: getFacilityFavouriteColumnsMutate } = useGetFavouriteColumns(
    EntityKind.Facility,
    handleFacilityFavouriteColumnsLoaded
  );
  useEffect(() => {
    updateUser(dispatch, user);
    if (user) {
      userSettingsDefaultsQuery.refetch();
    }
  }, [user]);

  // Handle the map default settings loading.
  useEffect(() => {
    if (defaults?.mapSettings) {
      const newMapState = {
        lng: defaults.mapSettings.longitude,
        lat: defaults.mapSettings.latitude,
        zoom: defaults.mapSettings.zoom ?? DEFAULT_ZOOM
      };
      // confirm map state match the user set default map settings
      if (settingsLoaded) {
        storeDispatch(setIsDefaultSettingsLoaded(true));
      }
      storeDispatch(setMapState(newMapState));
    }
  }, [defaults?.mapSettings, settingsLoaded]);

  // Handle the color palette default settings loading.
  useEffect(() => {
    if (defaults?.colorPalette && colorPalettes) {
      const match = colorPalettes.find((p) => p.name === defaults.colorPalette.name);
      if (match) {
        storeDispatch(
          setActiveColorPalette({
            ...match,
            reverse: defaults.colorPalette.reverse
          })
        );
        refreshPreferredColorsInStore(match);
      }
    }
  }, [defaults?.colorPalette, colorPalettes]);

  useEffect(() => {
    if (defaults?.facilityPalette && colorPalettes) {
      const match = colorPalettes.find((p) => p.name === defaults.facilityPalette.name);
      if (match) {
        storeDispatch(
          setActiveFacilityColorPalette({
            ...match,
            reverse: defaults.colorPalette.reverse
          })
        );
        refreshPreferredColorsInStore(match);
      }
    }
  }, [defaults?.facilityPalette, colorPalettes]);

  // Handle no default focus tiles, so request the system default favourites.
  useEffect(() => {
    if (!settingsLoaded) return;

    if (!defaults?.focusTiles) {
      getFavouriteColumnsMutate();
    }
  }, [defaults?.focusTiles, defaults?.facilityFocusTiles, settingsLoaded]);

  useEffect(() => {
    if (!settingsLoaded) return;
    if (!defaults?.facilityFocusTiles) {
      getFacilityFavouriteColumnsMutate();
    }
  }, [defaults?.facilityFocusTiles, settingsLoaded]);

  // Handle no default project.
  const projectListQuery = useProjectListQuery({ isAutoRefetching: isAutoFetching });
  const [hasLoadedDefaultProject, setHasLoadedDefaultProject] = useState<boolean>(false);

  useEffect(() => {
    const defaultProject =
      Object.values(userSettingsDefaultsQuery?.data?.Project ?? {})?.[0] ?? undefined;

    if (
      !projectListQuery.isSuccess ||
      !userSettingsDefaultsQuery.isSuccess ||
      hasLoadedDefaultProject ||
      defaultProject
    ) {
      if (projectListQuery.isSuccess && defaultProject) {
        // ensure project default from user settings is the current project
        const matchedDefaultProjectId = projectListQuery.data?.find((project) => {
          return project.projectId === defaultProject.projectId;
        })?.projectId;
        if (matchedDefaultProjectId) {
          updateSettingProject(dispatch, {
            projectId: matchedDefaultProjectId
          });
        }
      }
      return;
    }

    const nextProjectId = projectListQuery.data[0].projectId;

    updateSettingProject(dispatch, {
      projectId: nextProjectId
    });

    setHasLoadedDefaultProject(true);
  }, [
    hasLoadedDefaultProject,
    isAutoFetching,
    projectListQuery.isSuccess,
    settingsLoaded,
    userSettingsDefaultsQuery?.data,
    userSettingsDefaultsQuery?.isSuccess
  ]);
};

export default useInitialUserContextLoader;
