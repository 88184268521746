import { ICheckedForecast } from "store/features";

import { ForecastFolder } from "models/UserArpsModel";

export const transformFolderToNestedTreeData = (
  data: ForecastFolder[]
): ForecastFolder[] => {
  const map = {};
  const treeData = [];

  data.forEach((item) => {
    map[item.folderId] = {
      ...item,
      key: item.folderId,
      children: item.children ? [...item.children] : [],
      type: "folder",
      isFolder: true
    };
    if (!item.parentId) {
      treeData.push(map[item.folderId]);
    } else {
      const parent = map[item.parentId];
      if (parent && !parent.children.find((c) => c.key === item.key)) {
        parent.children.push(map[item.folderId]);
      }
    }
  });

  const sortTree = (nodes: ICheckedForecast[]) => {
    // Don't need to sort for top level nodes, it's already sorted
    nodes.forEach((node) => sortTree(node.children));
  };

  sortTree(treeData);

  return treeData;
};
