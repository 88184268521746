export function getTypeWellNormalizationValue(normalize, wellData) {
  let normalizeValue = 0.0;
  if (normalize.per === 0) return normalizeValue;
  switch (normalize.field) {
    case "Well_Design.HzLength_m":
    case "Well_Design.HzLengthSimple_m":
    case "Well_Design.HzLengthDrilled_m":
    case "Well_Design.HzLengthCompleted_m":
      normalizeValue = calculateNormalization(wellData.length, normalize);
      break;
    case "Frac_Data.StagesActual":
      normalizeValue = calculateNormalization(wellData.stage, normalize);
      break;
    case "Frac_Data.ProppantTotal_t":
      normalizeValue = calculateNormalization(wellData.proppant, normalize);
      break;
    case "Frac_Data.StageSpacing_m":
      normalizeValue = calculateNormalization(wellData.stage_spacing, normalize);
      break;
    case "Frac_Data.ProppantIntensity_tm":
      normalizeValue = calculateNormalization(wellData.proppant_intensity, normalize);
      break;
    default:
      wellData.additional_data?.forEach((ad) => {
        if (ad.field === normalize.field) {
          normalizeValue = calculateNormalization(ad.value, normalize);
        }
      });
      break;
  }
  return normalizeValue;
}

function calculateNormalization(
  wellData: number,
  normalize: {
    useMultilinearNormalization: boolean;
    threshold: number;
    higherScalar: number;
    per: number;
  }
): number {
  if (wellData <= 0) return 0.0;

  return normalize.useMultilinearNormalization
    ? calculateMultilinear(
        wellData,
        normalize.threshold,
        normalize.higherScalar,
        normalize.per
      )
    : 1.0 / (wellData / normalize.per);
}

function calculateMultilinear(
  wellValue: number,
  threshold: number,
  scaleAfterThreshold: number,
  normalizeByValue: number
): number {
  const val =
    threshold > normalizeByValue
      ? normalizeByPerValue(wellValue, threshold, normalizeByValue, scaleAfterThreshold)
      : scaleUpToNormalizeByValue(
          wellValue,
          threshold,
          normalizeByValue,
          scaleAfterThreshold
        );
  return 1.0 / val;
}

function normalizeByPerValue(
  wellValue: number,
  threshold: number,
  normalizeByValue: number,
  scaleAfterThreshold: number
): number {
  if (threshold >= wellValue) {
    return wellValue / normalizeByValue;
  }

  const upToThreshold = threshold / normalizeByValue;
  const scaledRemainingAfterThreshold =
    ((wellValue - threshold) * (1.0 / scaleAfterThreshold)) / normalizeByValue;

  return upToThreshold + scaledRemainingAfterThreshold;
}

function scaleUpToNormalizeByValue(
  wellValue: number,
  threshold: number,
  normalizeByValue: number,
  scaleAfterThreshold: number
): number {
  if (threshold > wellValue) {
    return (
      1.0 /
      ((threshold / wellValue) *
        ((threshold + (normalizeByValue - threshold) * scaleAfterThreshold) / threshold))
    );
  } else {
    return (
      1.0 /
      ((wellValue + (normalizeByValue - wellValue) * scaleAfterThreshold) / wellValue)
    );
  }
}
