import { useMemo } from "react";

import { ALL_CHART_TYPES, DEFAULT_AXIS_FONT_WEIGHT } from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";

import { IChartScreenshotSettings, IChartType } from "models/chart";

import {
  useScreenshotContext,
  useScreenshotPresetContext
} from "components/screenshot/hooks";

const getChartType = (chartTypeId: number): IChartType => {
  if (chartTypeId === null) return null;
  const chartType = Object.values(ALL_CHART_TYPES).find(
    (chartType) => chartType.id === chartTypeId
  );

  return chartType;
};

function useChartScreenshotCalculatedDefaults(): IChartScreenshotSettings {
  // hooks
  const { widget, widgetState } = useScreenshotContext();
  const { selectedPreset: preset } = useScreenshotPresetContext();

  const chartType = getChartType(widgetState?.request?.chartSetting?.chartType);

  const isBoxPlot = chartType === ALL_CHART_TYPES.BoxPlot;
  const isCrossPlot = chartType === ALL_CHART_TYPES.CrossPlot;
  const isMaterialBalanceTime = chartType === ALL_CHART_TYPES.MaterialBalanceTime;
  const isStackedBar = chartType === ALL_CHART_TYPES.StackedBar;
  const isProbit = chartType === ALL_CHART_TYPES.Probit;
  const isRateDate = chartType === ALL_CHART_TYPES.RateDate;
  const isCagrChart = chartType === ALL_CHART_TYPES.CAGR;
  const isBaseDeclineRateChart = chartType === ALL_CHART_TYPES.BaseDeclineRate;
  const isTotalRateDate = chartType === ALL_CHART_TYPES.TotalRateDate;
  const isWellContribution = chartType === ALL_CHART_TYPES.WellContribution;
  const showXMajorLines = !(
    isRateDate ||
    isCagrChart ||
    isBaseDeclineRateChart ||
    isTotalRateDate ||
    isCrossPlot ||
    isBoxPlot ||
    isWellContribution ||
    isStackedBar
  );

  const showXMinorLines =
    widgetState?.options?.xAxis?.type === "log" || isProbit || isMaterialBalanceTime;
  const showYMinorLines =
    widgetState?.options?.yAxis[0]?.type === "log" || isMaterialBalanceTime;

  let xAxisTitle = widgetState?.response?.layout?.xAxis?.title;
  if (isBoxPlot && widgetState?.request?.showScatter) {
    xAxisTitle = widgetState?.options?.xAxis?.name;
  }
  return useMemo(() => {
    return widget?.widgetComponentType !== "chart"
      ? {}
      : {
          axisFontSize: preset?.axisFontSize,
          titleFontSize: preset?.titleFontSize,
          axisLabelFontSize: preset?.axisLabelFontSize,
          titleText: widgetState?.response?.title?.toUpperCase() ?? undefined,
          showAxisTitle: true,
          xAxisTitleText: xAxisTitle,
          yAxisTitleText: widgetState?.response?.layout?.yAxis?.title,
          axisFontWeight: DEFAULT_AXIS_FONT_WEIGHT,
          showAxisLabels: true,
          legendShowCounts: true,
          legendOpacity: 1,
          fontFamily: THEME_FONT_FAMILY,
          gridShowXLines: showXMajorLines,
          gridShowXMinorLines: showXMinorLines,
          gridShowYLines: true,
          gridShowYMinorLines: showYMinorLines,
          legendVisible: true,
          legendBorderVisible: true,
          legendFontSize: preset?.legendFontSize,
          applyAbbreviations: false
        };
  }, [
    widget?.widgetComponentType,
    preset?.axisFontSize,
    preset?.titleFontSize,
    preset?.axisLabelFontSize,
    preset?.legendFontSize,
    widgetState?.response?.title,
    widgetState?.response?.layout?.xAxis?.title,
    widgetState?.response?.layout?.yAxis?.title,
    showXMajorLines,
    showXMinorLines
  ]);
}

export default useChartScreenshotCalculatedDefaults;
