import { DEFAULT_AXIS_LABEL_FONT_SIZE } from "../../../constants";
import { LegendItemModel } from "../../../models";
import { Annotation, EChartsSeries, IChartSeries, IChartSettings } from "../../../models/chart";
import { ChartSeries } from "../../../models/model";
import { getLogMinForAxis } from "../../../utils";
import { MinMaxT } from "../ChartWrapper";
import { StateT } from "../context";
import {
  getAxisTitleTruncatingWidth,
  getLabelWithPercentageSuffix
} from "../utils";
import { ChartT } from "./types";

export class BaseChart implements ChartT {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCustomLegendTitle(state: StateT): string {
    return "";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCustomLegendItems(series: ChartSeries[]): LegendItemModel[] {
    return [];
  }

  finalizeSeries(series: EChartsSeries): EChartsSeries {
    return series;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSeriesStackGroup(series: IChartSeries): string {
    return "";
  }

  getXAxisType(): "Date" | "Cum" {
    return "Date";
  }

  getDefaultAxisMinMax(): MinMaxT {
    return {
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null
    };
  }

  getYAxis({ log, chartData, responseData, yAxisZoom, bounds, screenshot }): unknown[] {
    const yAxis = [];
    const defaultAxisMinMax = this.getDefaultAxisMinMax();
    const yAxisMaxValue =
      !yAxisZoom?.yMax || isNaN(Number(yAxisZoom.yMax))
        ? defaultAxisMinMax.yMax
        : Number(yAxisZoom.yMax);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const axis1: any = {
      id: "yAxis",
      triggerEvent: true,
      max: yAxisMaxValue,
      min:
        !yAxisZoom?.yMin || isNaN(Number(yAxisZoom.yMin))
          ? defaultAxisMinMax.yMin
          : Number(yAxisZoom.yMin),
      type: "value",
      name: chartData.yAxisTitle,
      nameGap: 50,
      nameLocation: "middle",
      nameTextStyle: {
        // The bottom padding will shift the name
        // so that it does not overlap with the axis-labels (top-left-bottom-right).
        padding: [0, 0, 5, 0]
      },
      nameTruncate: {
        maxWidth: getAxisTitleTruncatingWidth(bounds, screenshot),
        ellipsis: "..."
      },
      axisLine: {
        show: true,
        onZero: false,
        lineStyle: { color: "rgb(155,155,155)", width: 1 }
      },
      splitLine: {
        lineStyle: {
          color: "#D9D9D9"
        }
      },
      axisTick: {
        show: true,
        lineStyle: {
          width: 1
        }
      },
      axisLabel: {
        fontWeight: 500,
        color: "#5B6770",
        fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE,
        margin: 8,
        formatter: (value) => {
          return getLabelWithPercentageSuffix(
            chartData.chartType,
            chartData.product,
            value
          );
        }
      }
    };

    if (
      responseData.layout?.yAxis &&
      responseData.layout.yAxis.defaultAxisType === "year"
    ) {
      axis1.axisLabel.formatter = (val) => val.toString();
    }

    if (responseData.layout?.yAxis && responseData.layout.yAxis.yScaleToX !== 1) {
      axis1.yAxisScaleToX = responseData.layout.yAxis.yScaleToX;
    }
    if (log) {
      axis1.type = "log";
      axis1.min =
        yAxisZoom.yMin === null
          ? getLogMinForAxis(responseData.layout)
          : Number(yAxisZoom.yMin);
      if (yAxisZoom.yMax !== null) {
        axis1.max = Number(yAxisZoom.yMax);
      }

      // Minor gridlines are important when looking at a plot in log scale for accurate data interpretation.
      axis1.minorSplitLine = {
        show: true,
        lineStyle: { color: "#F2F2F2" }
      };

      yAxis.push(axis1);
    }

    if (!log) {
      yAxis.push(axis1);
    }
    return yAxis;
  }

  getDefaultAxis(sessionMinMax?: MinMaxT): MinMaxT {
    if (!sessionMinMax) {
      return {
        xMin: null,
        xMax: null,
        yMin: null,
        yMax: null
      };
    }
    const yMax = sessionMinMax?.yMax ? parseFloat(sessionMinMax?.yMax.toString()) : null;
    const yMin = sessionMinMax?.yMin ? parseFloat(sessionMinMax?.yMin.toString()) : null;
    const xMax = sessionMinMax?.xMax ? parseFloat(sessionMinMax?.xMax.toString()) : null;
    const xMin = sessionMinMax?.xMin ? parseFloat(sessionMinMax?.xMin.toString()) : null;
    return {
      xMin,
      xMax,
      yMin,
      yMax
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMarkLineData(annotations: Annotation[], yMin: number) {
    return {};
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  applySeriesFiltering(series: IChartSeries[], settings: IChartSettings): IChartSeries[] {
    return series;
  }
}
