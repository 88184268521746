import { useState } from "react";

import { Select } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import styled from "styled-components";

import { useGetGeoCalculationsJobs } from "components/org-settings/hooks/useGetGeoCalculationsJobs";
import { GeoCalculationsJob } from "components/org-settings/models/GeoCalculations";

import FieldGroup from "./FieldGroup";
import { groupFields } from "./helpers";
import { useSelectedCategory } from "./hooks";
import useOrgGeoSelector from "./hooks/useOrgGeoSelector";

function OrgGeoCategory() {
  const [selectedJob, setSelectedJob] = useState<string>(undefined);
  const { data: jobs } = useGetGeoCalculationsJobs();

  const selectedCat = useSelectedCategory();

  const orgGeoColumns = useOrgGeoSelector(selectedJob);

  const groupedColumns = groupFields(orgGeoColumns);

  const removeGroupNameFromTitle = (title: string) => {
    if (!title) return title;
    return title?.indexOf(" - ") > -1
      ? title.substring(title?.indexOf(" - ") + 3)
      : title;
  };

  const groupedFields = Object.keys(groupedColumns).reduce((acc, key) => {
    const list = groupedColumns[key];
    const fields = list
      .map((field) => ({
        ...field,
        title: removeGroupNameFromTitle(field.title)
      }))
      .sort((a, b) => a.withinSubgroupOrder - b.withinSubgroupOrder);
    if (fields.length > 0) {
      acc.push({ key, fields });
    }
    return acc;
  }, []);

  const changeCalcJob = () => (value) => {
    setSelectedJob(value);
  };

  // width based off of (--field-col-width - --space-4*2) / number of columns => (300-(16*2))/4 = 67
  const columns: ColumnsType<GeoCalculationsJob> = [
    {
      title: "Left Width",
      dataIndex: "leftWidth",
      key: "leftWidth",
      width: 67
    },
    {
      title: "Right Width",
      dataIndex: "rightWidth",
      key: "rightWidth",
      width: 67
    },
    {
      title: "Up Height",
      dataIndex: "upHeight",
      key: "upHeight",
      width: 67
    },
    {
      title: "Down Height",
      dataIndex: "downHeight",
      key: "downHeight",
      width: 67
    }
  ];

  if (!selectedCat?.isOrgGeoDefinedColumn || !jobs) return null;
  const jobsOptions = jobs?.length > 0 ? jobs : [];

  return (
    <OrgGeoCategoryWrapper>
      <WrapperRow>
        <div>
          <Wrapper>Name</Wrapper>
          <Wrapper>
            <Select
              data-testid="geo-calc-job"
              value={selectedJob}
              onChange={changeCalcJob()}
              options={jobsOptions.map((job) => ({ value: job.name, label: job.name }))}
              popupClassName="modal-select"
            />
          </Wrapper>
        </div>
        {selectedJob && (
          <TableWrapperRow>
            <TableWrapper>
              <Table
                data-testid="geo-calc-table"
                dataSource={jobsOptions.filter((j) => j.name === selectedJob)}
                columns={columns}
                rowKey="jobId"
                pagination={false}
                size="small"
                bordered={true}
              />
            </TableWrapper>
          </TableWrapperRow>
        )}
      </WrapperRow>
      <FieldListWrapper>
        {groupedFields &&
          groupedFields.map((group) => (
            <FieldGroup
              key={group.key}
              groupName={group.key}
              fields={group.fields}
              groups={null}
              dragHandleProps={null}
              isDragging={false}
              shortcutsEnabled={false}
            />
          ))}
      </FieldListWrapper>
    </OrgGeoCategoryWrapper>
  );
}

export default OrgGeoCategory;

const OrgGeoCategoryWrapper = styled.div`
  display: flex;
  background-color: var(--color-white);
  max-height: 100%;
  flex-direction: column;
`;

const FieldListWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-content: center;
  padding: 0 var(--space-2);
`;

const WrapperRow = styled.div`
  display: flex;
  padding: 0 var(--space-2);
  gap: 10px;
  flex-direction: column;
`;

const TableWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-content: center;
  padding: 0 var(--space-2);
`;

const TableWrapperRow = styled.div`
  display: flex;
  flex-direction: column;
`;
