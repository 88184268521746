import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { SegmentTemplate } from "../models/SegmentTemplate";
import useExtendSegments from "../utils/ExtendSegment";
import useInitialSegments from "../utils/InitialSegment";
import {
  updateOneSegmentTerminalDecline,
  updateThreeSegmentTerminalDecline,
  updateThreeSegmentTerminalDeclineWithConstrainedPeriod,
  updateThreeSegmentTerminalDeclineWithRampUp,
  updateTwoSegmentTerminalDecline,
  updateTwoSegmentTerminalDeclineWithRampUp
} from "../utils/UpdateSegment";

export default function useSegmentTemplates(): SegmentTemplate[] {
  const typeWellSettings = useSelector(
    (state: RootState) => state.userSetting.typeWellSettings
  );

  const {
    initialOneSegmentTerminalDecline,
    initialTwoSegmentTerminalDecline,
    initialTwoSegmentTerminalDeclineWithRampUp,
    initialThreeSegmentTerminalDecline,
    initialThreeSegmentTerminalDeclineWithRampUp,
    initialThreeSegmentTerminalDeclineWithConstrainedPeriod
  } = useInitialSegments();

  const {
    extendOneSegmentTerminalDecline,
    extendTwoSegmentTerminalDecline,
    extendTwoSegmentTerminalDeclineWithRampUp,
    extendThreeSegmentTerminalDecline,
    extendThreeSegmentTerminalDeclineWithRampUp,
    extendThreeSegmentTerminalDeclineWithConstrainedPeriod
  } = useExtendSegments();

  return useMemo(
    () => [
      {
        type: 1,
        productCols: [],
        productData: [],
        update: updateOneSegmentTerminalDecline,
        initialData: initialOneSegmentTerminalDecline,
        extendArps: extendOneSegmentTerminalDecline
      } as SegmentTemplate,
      {
        type: 2,
        productCols: [],
        productData: [],
        update: updateTwoSegmentTerminalDecline,
        initialData: initialTwoSegmentTerminalDecline,
        extendArps: extendTwoSegmentTerminalDecline
      } as SegmentTemplate,
      {
        type: 3,
        productCols: [],
        productData: [],
        update: updateTwoSegmentTerminalDeclineWithRampUp,
        initialData: initialTwoSegmentTerminalDeclineWithRampUp,
        extendArps: extendTwoSegmentTerminalDeclineWithRampUp
      } as SegmentTemplate,
      {
        type: 4,
        productCols: [],
        productData: [],
        update: updateThreeSegmentTerminalDecline,
        initialData: initialThreeSegmentTerminalDecline,
        extendArps: extendThreeSegmentTerminalDecline
      } as SegmentTemplate,
      {
        type: 5,
        productCols: [],
        productData: [],
        update: updateThreeSegmentTerminalDeclineWithRampUp,
        initialData: initialThreeSegmentTerminalDeclineWithRampUp,
        extendArps: extendThreeSegmentTerminalDeclineWithRampUp
      } as SegmentTemplate,
      {
        type: 6,
        productCols: [],
        productData: [],
        update: updateThreeSegmentTerminalDeclineWithConstrainedPeriod,
        initialData: initialThreeSegmentTerminalDeclineWithConstrainedPeriod,
        extendArps: extendThreeSegmentTerminalDeclineWithConstrainedPeriod
      } as SegmentTemplate
    ],
    [typeWellSettings]
  );
}
