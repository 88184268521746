import { useQuery } from "@apollo/client";
import { ForecastMonth } from "arps_wasm";

import { FETCH_FORECAST_ARRAY } from "api/userArps";

export interface ForecastArrayT {
  forecastArray: { [key: string]: ForecastMonth[] };
}

export const useFetchForecastArray = (params: { ids: string[] }) => {
  const { ids } = params;
  return useQuery<ForecastArrayT>(FETCH_FORECAST_ARRAY, {
    variables: {
      req: {
        ids: ids
      }
    }
  });
};
