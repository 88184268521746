import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { DATA_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { NetworkFraction } from "./useGetFacilityNetworkFraction";

const API_URL = `${DATA_SERVICE_ADDRESS}/api/v1/midstream/facility-well-network`;

const fetchNetworkFraction = async (facilityId: string) => {
  if (!facilityId) {
    return Promise.resolve([]);
  }
  const response = await axios.get<NetworkFraction[]>(`${API_URL}/${facilityId}`);
  const data: NetworkFraction[] = response?.data;
  if (!Array.isArray(data)) {
    return [];
  }
  return response?.data ?? [];
};

const useGetFacilityContributingWellsNetwork = () => {
  return useMutation<NetworkFraction[], AxiosError, string>(fetchNetworkFraction);
};

export default useGetFacilityContributingWellsNetwork;
