import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "antd";
import { ALL_CHART_TYPES } from "constants/chart.constants";
import { dequal } from "dequal";
import _ from "lodash";
import { addMultipleLegendItemsToFilter, filterWellsFromList } from "store/features";
import {
  addMultipleLegendItemsToFilter as addMultipleLegendItemsToFacilitiesFilter,
  filterWellsFromList as filterFacilitiesFromList
} from "store/features/filter/facilityFilterSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { EntityKind } from "models/entityKind";

import {
  toggleLassoOFF,
  updateBrushedList,
  useChartDispatch,
  useChartState
} from "components/chart/context";
import { FilterMinus } from "components/icons";
import FilterPlusIcon from "components/icons/FilterPlus";

const AddToFilterButton = () => {
  const dispatch = useDispatch();
  const chartDispatch = useChartDispatch();
  const { brushedList, options, settings, entityKind, lasso } = useChartState();

  const wellListFilter = useSelector((state: RootState) => state.filter.wellListFilter);
  const uwiList = useMemo(() => options?.uwiList ?? [], [options?.uwiList]);

  // as brushed list synchronized with wellListFilter, clear list in context
  useEffect(() => {
    const listFromFilter = wellListFilter?.[0]?.value;
    if (!listFromFilter?.length) return;
    if (!dequal(listFromFilter, brushedList)) return;

    updateBrushedList(chartDispatch, []);
  }, [brushedList, chartDispatch, wellListFilter]);

  // add wells to filter and clear brushedList in context
  const handleAddToFilter = () => () => {
    const list = [...brushedList];
    if (
      settings.chartType === ALL_CHART_TYPES.CrossPlot.label ||
      settings.chartType === ALL_CHART_TYPES.Probit.label ||
      (settings.chartType === ALL_CHART_TYPES.BoxPlot.label && settings.showScatter)
    ) {
      dispatch(
        entityKind === EntityKind.Facility
          ? filterFacilitiesFromList(list)
          : filterWellsFromList(list)
      );
    } else {
      dispatch(
        entityKind === EntityKind.Facility
          ? addMultipleLegendItemsToFacilitiesFilter(
              list,
              false,
              settings.groupBy?.property
            )
          : addMultipleLegendItemsToFilter(list, false, settings.groupBy?.property)
      );
    }
    if (lasso) {
      toggleLassoOFF(chartDispatch);
    }
  };

  const handleExcludeFromFilter = () => () => {
    if (
      settings.chartType === ALL_CHART_TYPES.CrossPlot.label ||
      settings.chartType === ALL_CHART_TYPES.Probit.label ||
      (settings.chartType === ALL_CHART_TYPES.BoxPlot.label && settings.showScatter)
    ) {
      const filteredList = _.difference(uwiList, brushedList);
      const list = [...filteredList];
      dispatch(
        entityKind === EntityKind.Facility
          ? filterFacilitiesFromList(list)
          : filterWellsFromList(list)
      );
    } else {
      dispatch(
        entityKind === EntityKind.Facility
          ? addMultipleLegendItemsToFacilitiesFilter(
              brushedList,
              false,
              settings.groupBy?.property
            )
          : addMultipleLegendItemsToFilter(brushedList, true, settings.groupBy?.property)
      );
    }
    if (lasso) {
      toggleLassoOFF(chartDispatch);
    }
  };

  return (
    <>
      <ButtonContainer>
        <StyledButton type="text" onClick={handleAddToFilter()}>
          <FilterPlusIcon />
          Add to filter
        </StyledButton>
        <StyledButton type="text" onClick={handleExcludeFromFilter()}>
          <FilterMinus />
          Exclude from filter
        </StyledButton>
      </ButtonContainer>
    </>
  );
};

export default memo(AddToFilterButton);

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
