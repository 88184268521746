import { ReactNode, memo } from "react";

import classnames from "classnames";
import styled from "styled-components/macro";

import { Tooltip } from "components/base";

type ThreeDVToolbarButtonT = {
  icon: ReactNode;
  isMenuButton?: boolean;
  activeIcon?: ReactNode;
  className?: string;
  tooltipText?: string | ReactNode;
  overflowLabel?: string;
  active: boolean;
  onToggle: (b: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const ThreeDVToolbarButton = ({
  icon,
  isMenuButton = false,
  activeIcon = null,
  className = "",
  tooltipText = "",
  active = false,
  onToggle = null,
  disabled = false,
  ...rest
}: ThreeDVToolbarButtonT) => {
  const buttonClassNames = classnames("toolbar-button", className);

  return (
    <Tooltip title={tooltipText}>
      <ButtonWrapper>
        <StyledButton
          className={buttonClassNames}
          aria-pressed={!disabled && active}
          active={!disabled && active}
          onClick={() => onToggle(!active)}
          disabled={disabled}
          {...rest}>
          {activeIcon && active ? activeIcon : icon}
        </StyledButton>
        {isMenuButton && <MenuButtonIndicator active={!disabled && active} />}
      </ButtonWrapper>
    </Tooltip>
  );
};

export default memo(ThreeDVToolbarButton);

const StyledButton = styled.button`
  width: 30px;
  height: 30px;
  display: grid;
  grid-template-columns: 30px;
  gap: 6px;
  align-items: center;
  justify-items: center;
  border-radius: 3px;
  background: ${(props) =>
    props.isGlobalSetting && props.active ? "var(--color-primary)" : "transparent"};
  color: ${(props) =>
    props.isGlobalSetting && props.active
      ? "white"
      : props.active
      ? "var(--color-primary)"
      : "#a2aaad"};
  &:hover {
    color: var(--color-primary);
    background: ${(props) =>
      props.isGlobalSetting && props.active ? "white" : "transparent"};
  }
  border: none;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

const MenuButtonIndicator = styled.div`
  position: absolute;
  top: 22px;
  left: 22px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent; /* Adjust triangle size */
  border-bottom: 8px solid;

  border-bottom-color: ${(props) =>
    props.isGlobalSetting && props.active
      ? "white"
      : props.active
      ? "var(--color-primary)"
      : "#a2aaad"};
`;

const ButtonWrapper = styled.div`
  position: relative;

  &:hover ${MenuButtonIndicator} {
    border-bottom-color: ${(props) =>
      props.isGlobalSetting && props.active ? "white" : "var(--color-primary)"};
  }
`;
