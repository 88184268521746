// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiCubeOutline } from "@mdi/js";
import { Popover } from "antd";

import { useVisState } from "../../context";
import Xda3DGeoOptions from "./Xda3DGeoOptions";
import XdaToolbarButton from "./XdaToolbarButton";

interface Xda3DGeoToggleProps {
  binUpdateErrorMessage: string;
}

function Xda3DGeoToggle({ binUpdateErrorMessage }: Xda3DGeoToggleProps) {
  const [{ xda }] = useVisState();
  const { showIpdb } = xda.settings;
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={<Xda3DGeoOptions binUpdateErrorMessage={binUpdateErrorMessage} />}
      trigger="click"
      placement="bottomRight"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <XdaToolbarButton
        isMenuButton={true}
        icon={<Icon path={mdiCubeOutline} size={1.75} />}
        active={showIpdb}
        tooltipText={"3D Geo Model"}
        overflowLabel={"3D Geo Model"}
        onToggle={(v) => setVisible(v)}
      />
    </Popover>
  );
}

export default Xda3DGeoToggle;
