import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Input, Select } from "antd";
import { RADIUS_DIMENSION_MIN } from "constants/visualization.constants";
import { setVisSettings } from "store/features";
import styled from "styled-components/macro";

import { useDebounce } from "hooks";

import { updateXDASettings, useVisState } from "../../context";
import { XDA_RADIUS_TYPES } from "../../context/types";
import { XdaOptionItem, XdaOptionLabel, XdaOptionsWrapper } from "./XdaOptionsLayout";

const XdaRadiusOptions = () => {
  const dispatch = useDispatch();
  const [{ xda }, visDispatch] = useVisState();
  const { downHeight, leftWidth, rightWidth, upHeight } = xda.settings;

  const debouncedUpHeight = useDebounce(upHeight, 1200);
  const debouncedDownHeight = useDebounce(downHeight, 1200);
  const debouncedLeftWidth = useDebounce(leftWidth, 1200);
  const debouncedRightWidth = useDebounce(rightWidth, 1200);

  // sync option changes with context
  const handleSettingChange = (key) => (value) => {
    let updatedValue = value;
    if (key === "downHeight" || key === "rightWidth") {
      //Convert value to 32-bit unsigned integer if handlesSettingChange is wrapped in inputWrapper. Math.max is used to ensure does not go below min if input inputted via typing
      updatedValue = Math.max(RADIUS_DIMENSION_MIN, value * 1);
    }

    const nextSettings = { ...xda.settings, [key]: updatedValue };
    updateXDASettings(visDispatch, nextSettings);
  };

  // sync width/height updates with store/app/visSettings
  useEffect(() => {
    dispatch(
      setVisSettings({
        upHeight: debouncedUpHeight,
        downHeight: debouncedDownHeight,
        leftWidth: debouncedLeftWidth,
        rightWidth: debouncedRightWidth
      })
    );
  }, [
    debouncedLeftWidth,
    debouncedRightWidth,
    debouncedDownHeight,
    debouncedUpHeight,
    dispatch
  ]);

  const handleTopChange = (value) => {
    //Convert value to 32-bit unsigned integer as handleTopChange is wrapped in inputWrapper. Math.max to ensure does not go below min if input is inputted via typing
    const updatedValue = Math.max(RADIUS_DIMENSION_MIN, value * 1);

    const nextSettings = {
      ...xda.settings,
      upHeight: updatedValue,
      downHeight: updatedValue
    };

    updateXDASettings(visDispatch, nextSettings);
  };

  const handleLeftChange = (value) => {
    //Convert value to 32-bit unsigned integer as handleLeftChange is wrapped in inputWrapper. Math.max to ensure does not go below min if input is inputted via typing
    const updatedValue = Math.max(RADIUS_DIMENSION_MIN, value * 1);

    const nextSettings = {
      ...xda.settings,
      leftWidth: updatedValue,
      rightWidth: updatedValue
    };

    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <XdaOptionsWrapper
      width={"300px"}
      paddingRight={"12px"}
      cardBodyPadding={"5px 5px 5px 5px"}>
      <XdaOptionItem>
        <XdaOptionLabel>Select Radius</XdaOptionLabel>
        <Select
          size="small"
          value={xda.settings.selectedRadius}
          onChange={(value) => {
            handleSettingChange("selectedRadius")(value);
          }}>
          <option value={XDA_RADIUS_TYPES.Gross}>Gross</option>
          <option value={XDA_RADIUS_TYPES.SweetSpot}>Sweet Spot</option>
          <option value={XDA_RADIUS_TYPES.LandingZone}>Landing Zone</option>
          <option value={XDA_RADIUS_TYPES.Custom}>Custom</option>
        </Select>
      </XdaOptionItem>

      <XdaOptionItem>
        <XdaOptionLabel>Up (m)</XdaOptionLabel>
        <OptionItemRightGroup>
          <InputWrapper
            disabled={xda.settings.selectedRadius != XDA_RADIUS_TYPES.Custom}
            min={RADIUS_DIMENSION_MIN}
            onChange={(e) => handleTopChange(e.target.value)}
            size="small"
            step={1}
            type="number"
            value={upHeight}
          />
        </OptionItemRightGroup>
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Down (m)</XdaOptionLabel>
        <InputWrapper
          disabled={xda.settings.selectedRadius != XDA_RADIUS_TYPES.Custom}
          min={RADIUS_DIMENSION_MIN}
          onChange={(e) => handleSettingChange("downHeight")(e.target.value)}
          size="small"
          step={1}
          type={"number"}
          value={downHeight}
        />
      </XdaOptionItem>
      <SmallPadding />
      <XdaOptionItem>
        <XdaOptionLabel>Left (m)</XdaOptionLabel>
        <OptionItemRightGroup>
          <InputWrapper
            disabled={xda.settings.selectedRadius != XDA_RADIUS_TYPES.Custom}
            min={RADIUS_DIMENSION_MIN}
            onChange={(e) => handleLeftChange(e.target.value)}
            size="small"
            step={10}
            type={"number"}
            value={leftWidth}
          />
        </OptionItemRightGroup>
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Right (m)</XdaOptionLabel>
        <InputWrapper
          disabled={xda.settings.selectedRadius != XDA_RADIUS_TYPES.Custom}
          min={RADIUS_DIMENSION_MIN}
          onChange={(e) => handleSettingChange("rightWidth")(e.target.value)}
          size="small"
          step={10}
          type={"number"}
          value={rightWidth}
        />
      </XdaOptionItem>
    </XdaOptionsWrapper>
  );
};

export default XdaRadiusOptions;

const InputWrapper = styled(Input)`
  max-width: 100px;
  border-radius: var(--border-radius);
`;

const OptionItemRightGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 5px;

  .ant-slider {
    width: 80px;
  }

  .ant-input-number {
    max-width: 130px;
    width: 130px;
  }

  .ant-select {
    width: 130px;
  }

  .ant-btn {
    border-radius: 5px;
  }

  .ant-btn-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  padding: 2px 0;
`;

const SmallPadding = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
