import { useEffect, useRef, useState } from "react";

import { Input, Select, Switch } from "antd";
import { BUBBLE_LAYER, COVERAGE_LAYER } from "constants/mapLayers.constants";
import { scaleByOptions } from "constants/visualization.constants";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { getCompletion, getExtrusion } from "api/map";

import { BaseDropdown, BaseIconToggle, BaseInput, BaseTooltip } from "components/base";
import BaseMenu from "components/base/BaseMenu";
import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import { Bubble } from "components/icons";

import { TxnT } from "../../../models";
import useGetCoverage from "../hooks/useGetCoverage";
import { useMapDispatch } from "../hooks/useMapDispatch";
import { WellBubbleVis } from "./WellBubbleVis";

export interface MapVisComponentModel {
  mapbox: mapboxgl.Map;
  filterId;
  txnId;
  removeLayer;
  addLayer;
}

export type CoverageType = "Bubble" | "WellOutline";

export type BubbleOptionsT = {
  visible: boolean;
  maxRadiusSizeMeters: number;
  location: "midpoint" | "bottom" | "surface";
  opacity: number;
  visualType?: "showBubble" | "showExtrude";
};

export default function MapVis({
  mapbox,
  filterId,
  txnId,
  removeLayer,
  addLayer
}: MapVisComponentModel) {
  const [visualizationOption, setVisualizationOption] = useState(null);
  const [bubbleExtrudeField, setBubbleExtrudeField] = useState<
    { property: string; title: string }[]
  >([]);
  //bubble field
  const [canShowBubble, setCanShowBubble] = useState(true);
  const [, setShowBubbleExtrudeFields] = useState(false);
  const [scaleByProperty, setScaleByProperty] = useState(scaleByOptions[0]);
  const [scaleByValue, setScaleByValue] = useState(null);
  const mapDispatch = useMapDispatch();
  const [completionLengthValue, setCompletionLengthValue] = useState(500);
  const [bubbleOptions, setBubbleOptions] = useState<BubbleOptionsT>({
    maxRadiusSizeMeters: 200,
    location: "midpoint",
    opacity: 100,
    visible: true,
    visualType: "showBubble"
  });
  const [showCompletion, setShowCompletion] = useState(false);
  const [canToggleCompletion, setCanToggleCompletion] = useState(true);
  const [canToggleWellCoverage, setCanToggleWellCoverage] = useState(false);
  const { isActive: isGeomBinOpen } = useGeomBinContext();
  const [coverageOptions, setCoverageOptions] = useState({
    showCoverage: false,
    radius: 600,
    coverageType: "Bubble" as CoverageType
  });

  const { data: coverageData } = useGetCoverage(coverageOptions);
  const bubbleMinZoom = 9;
  const coverageMinZoom = 8;
  const completionMinZoom = 9;
  useEffect(() => {
    if (!scaleByProperty) {
      return;
    }
    setScaleByValue(scaleByProperty.defaultValue);
  }, [scaleByProperty]);

  useEffect(() => {
    if (!mapbox) {
      return;
    }

    function onZoomEnd() {
      if (!mapbox) {
        return;
      }
      const zoom = mapbox.getZoom();
      setCanShowBubble(zoom > bubbleMinZoom);
      setCanToggleCompletion(zoom > completionMinZoom);
      setCanToggleWellCoverage(zoom > coverageMinZoom);
    }

    mapbox.on("zoomend", onZoomEnd);
    return () => {
      if (mapbox) {
        mapbox.off("zoomend", onZoomEnd);
      }
    };
  }, [mapbox]);

  //bubble map layer
  useEffect(() => {
    if (!mapbox) {
      return;
    }

    if (!bubbleOptions.visualType || bubbleExtrudeField.length == 0 || !txnId) {
      removeLayer(BUBBLE_LAYER);
      if (mapbox.getSource(BUBBLE_LAYER)) {
        mapbox.removeSource(BUBBLE_LAYER);
      }
      return;
    }

    mapDispatch({
      payload: {
        isLoading: true
      }
    });
    if (
      bubbleOptions.visualType &&
      bubbleExtrudeField.length > 0 &&
      bubbleExtrudeField[0].property
    ) {
      getExtrusionDebounce(
        mapbox,
        txnId,
        bubbleExtrudeField,
        bubbleOptions,
        visualizationOption
      );
    }
  }, [bubbleExtrudeField, txnId, removeLayer, bubbleOptions, visualizationOption]);

  const getExtrusionDebounce = useRef(
    _debounce(
      (
        mapbox,
        txnId: TxnT,
        bubbleExtrudeField: {
          property: string;
          title: string;
          unit?: string;
          product?: string;
        }[],
        options: BubbleOptionsT,
        visualizationOption: string
      ) => {
        getExtrusion(
          txnId,
          options.visualType === "showBubble",
          bubbleExtrudeField,
          options.visualType === "showExtrude",
          options
        )
          .then((response) => {
            const data = response.data;
            mapDispatch({
              payload: {
                isLoading: false
              }
            });
            try {
              removeLayer(BUBBLE_LAYER);
              if (mapbox.getSource(BUBBLE_LAYER)) {
                mapbox.removeSource(BUBBLE_LAYER);
              }
              if (visualizationOption !== "showBubble") {
                return;
              }
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err);
              //empty
            }
            const fc = { ...emptyFeatureCollection };
            const valueKey =
              options.visualType === "showExtrude" ? "extrude-value" : "bubble-value";
            fc.features = data.data.features.filter((f) => {
              return f.properties[valueKey] > 0;
            });
            const units = [];
            let colors = [];
            const title =
              bubbleExtrudeField.length == 1
                ? bubbleExtrudeField[0].title.replace(/\(.*\)/, "")
                : "Multiple";
            if (data.colors.length > 1) {
              colors = data.colors.map((c, i) => {
                const field = bubbleExtrudeField[i];
                const name = field.title;
                if (field.unit && !units.includes(field.unit)) {
                  units.push(field.unit);
                }
                if (field.property === c.title) {
                  return {
                    title: `${field.product ? field.product + ` ${name}` : `${name}`}`,
                    color: c.color
                  };
                }
                return c;
              });
            } else if (bubbleExtrudeField.length > 0 && bubbleExtrudeField[0].unit) {
              units.push(bubbleExtrudeField[0].unit);
            }

            const hasGasAndOilUnits =
              bubbleExtrudeField.length > 1 &&
              bubbleExtrudeField.findIndex((f) =>
                f.property.startsWith("Performance.Oil")
              ) >= 0 &&
              bubbleExtrudeField.findIndex((f) =>
                f.property.startsWith("Performance.Gas")
              ) >= 0;
            mapDispatch({
              payload: {
                bubbleVisLegend: {
                  visible: options.visible && visualizationOption === "showBubble",
                  minValue: data.min,
                  maxValue: data.max,
                  title: `${title} (${hasGasAndOilUnits ? "MBOE" : units.join("+")})`,
                  colors
                }
              }
            });
            mapbox.addSource(BUBBLE_LAYER, { type: "geojson", data: fc });
            let paint: unknown = {
              "fill-color": ["get", "color"],
              "fill-outline-color": "#4b4a4a",
              "fill-opacity": options.opacity * 0.01
            };
            if (options.visualType === "showExtrude") {
              paint = {
                "fill-extrusion-color": ["get", "color"],
                "fill-extrusion-height": ["get", "extrude"],
                "fill-extrusion-base": 0,
                "fill-extrusion-opacity": 0.8
              };
            }
            const layer = {
              id: BUBBLE_LAYER,
              type: options.visualType === "showExtrude" ? "fill-extrusion" : "fill",
              source: BUBBLE_LAYER,
              layout: {
                visibility: "visible"
              },
              minzoom: bubbleMinZoom,
              paint
            };

            addLayer(layer);
          })
          .finally(() => {
            mapDispatch({
              payload: {
                isLoading: false
              }
            });
          });
      },
      2000
    )
  ).current;

  function removeCoverageLayer(mapbox) {
    if (!mapbox) {
      return;
    }
    try {
      removeLayer(COVERAGE_LAYER);
      if (mapbox.getSource(COVERAGE_LAYER)) {
        mapbox.removeSource(COVERAGE_LAYER);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      //empty
    }
  }

  const getCompletionDebounceRef = useRef(
    _debounce(
      (source, filterId, scaleByProperty, scaleByValue, completionLengthValue) => {
        mapDispatch({
          payload: {
            isLoading: true
          }
        });
        getCompletion(
          filterId?.id,
          scaleByProperty.field,
          scaleByValue,
          completionLengthValue
        )
          .then((response) => {
            const data = response.data;
            source.setData(data);
          })
          .finally(() => {
            mapDispatch({
              payload: {
                isLoading: false
              }
            });
          });
      },
      500
    )
  ).current;
  useEffect(() => {
    if (visualizationOption === "showCompletion") {
      setShowCompletion(true);
      setCoverageOptions({ ...coverageOptions, showCoverage: false });
      removeCoverageLayer(mapbox);
    } else if (visualizationOption === "showBubble") {
      setShowCompletion(null);
      setCoverageOptions({ ...coverageOptions, showCoverage: false });
      removeCoverageLayer(mapbox);
    } else if (visualizationOption === "showCoverage") {
      setShowCompletion(null);
      setCoverageOptions({ ...coverageOptions, showCoverage: true });
    } else {
      setShowCompletion(null);
      setCoverageOptions({ ...coverageOptions, showCoverage: false });
      removeCoverageLayer(mapbox);
    }
  }, [visualizationOption, mapbox]);

  useEffect(() => {
    function showCompletions() {
      if (!mapbox) {
        return;
      }
      const zoom = mapbox.getZoom();
      const source = mapbox.getSource("completion") as mapboxgl.GeoJSONSource;
      if (zoom < completionMinZoom || !showCompletion) {
        if (source) {
          source.setData(emptyFeatureCollection);
        }
        return;
      }
      getCompletionDebounceRef(
        source,
        filterId,
        scaleByProperty,
        scaleByValue,
        completionLengthValue
      );
    }

    showCompletions();
  }, [
    showCompletion,
    filterId,
    scaleByProperty,
    scaleByValue,
    completionLengthValue,
    getCompletionDebounceRef
  ]);

  useEffect(() => {
    if (!coverageOptions.showCoverage || !coverageData || !mapbox) {
      return;
    }
    removeCoverageLayer(mapbox);

    mapbox.addSource(COVERAGE_LAYER, { type: "geojson", data: coverageData });
    const paint: unknown = {
      "fill-color": ["get", "color"],
      "fill-outline-color": ["get", "color"],
      "fill-opacity": 0.4
    };

    addLayer({
      id: COVERAGE_LAYER,
      type: "fill",
      source: COVERAGE_LAYER,
      layout: {
        visibility: "visible"
      },
      minzoom: coverageMinZoom,
      paint
    });
  }, [coverageData, coverageOptions.showCoverage]);

  if (isGeomBinOpen) {
    return <></>;
  }

  return (
    <BaseMenu
      onClose={() => setShowBubbleExtrudeFields(false)}
      trigger={
        <BaseTooltip text="Visualization Options" className="item">
          <BaseIconToggle
            data-testid={"map-vis-options"}
            squareIcon
            className={
              (visualizationOption === "showBubble" && canShowBubble) ||
              (visualizationOption === "showCompletion" && canToggleCompletion)
                ? "isActive"
                : ""
            }>
            <Bubble size={24}></Bubble>
          </BaseIconToggle>
        </BaseTooltip>
      }>
      {() => (
        <div className="ipdb-options">
          <div className="ipdb-option-item bubble-toggle">
            <span style={{ fontWeight: "bold" }}>Show Bubble</span>
            <Switch
              disabled={!canShowBubble}
              checked={visualizationOption === "showBubble" && canShowBubble}
              onChange={(val) => {
                setVisualizationOption(val ? "showBubble" : null);
                mapDispatch({
                  payload: {
                    bubbleVisLegend: {
                      visible: val && bubbleExtrudeField.length > 0,
                      title: "",
                      maxValue: 0,
                      minValue: 0,
                      colors: []
                    }
                  }
                });
              }}
            />
          </div>

          <div className="separator" />

          {visualizationOption === "showBubble" && (
            <WellBubbleVis
              bubbleExtrudeField={bubbleExtrudeField}
              setBubbleExtrudeField={setBubbleExtrudeField}
              onChange={(opts) => {
                setBubbleOptions(opts);
              }}
              setShowBubbleExtrudeFields={setShowBubbleExtrudeFields}
              options={bubbleOptions}
            />
          )}

          <MapVisOptionItem className="ipdb-option-item bubble-toggle">
            <span style={{ fontWeight: "bold" }}>Show Completion</span>
            <Switch
              disabled={!canToggleCompletion}
              checked={visualizationOption === "showCompletion" && canToggleCompletion}
              onChange={(val) => {
                setVisualizationOption(val ? "showCompletion" : null);
              }}
            />
          </MapVisOptionItem>

          {visualizationOption === "showCompletion" && (
            <>
              <div className="separator" />
              <div className="ipdb-option-item scale-container">
                <div className="flex-column" style={{ gap: "3px" }}>
                  <span>Scale by</span>
                  <BaseDropdown
                    value={scaleByProperty}
                    options={scaleByOptions}
                    labelKey="value"
                    onChange={setScaleByProperty}
                  />
                </div>
                <div className="flex-column" style={{ gap: "3px" }}>
                  <span>Value ({scaleByProperty.unit})</span>
                  <BaseInput
                    value={scaleByValue}
                    onChange={setScaleByValue}
                    type="number"
                  />
                </div>
                <div className="flex-column" style={{ gap: "3px" }}>
                  <span>Length (m)</span>
                  <BaseInput
                    style={{ width: "80px" }}
                    value={completionLengthValue}
                    onChange={setCompletionLengthValue}
                    type="number"
                  />
                </div>
              </div>
            </>
          )}

          <div className="separator" />
          <MapVisOptionItem className={"ipdb-option-item coverage"}>
            <span style={{ fontWeight: "bold" }}>Show Well Coverage</span>
            <Switch
              disabled={!canToggleWellCoverage}
              checked={coverageOptions.showCoverage}
              onChange={(val) => {
                setVisualizationOption(val ? "showCoverage" : null);
                setCoverageOptions({ ...coverageOptions, showCoverage: val });
              }}
            />
          </MapVisOptionItem>

          {coverageOptions.showCoverage && (
            <>
              <div className="separator" />
              <div className="ipdb-option-item scale-container">
                <div className="flex-column" style={{ gap: "3px" }}>
                  <span>Radius (m)</span>
                  <Input
                    type={"number"}
                    step={1}
                    min={1}
                    max={10000}
                    value={coverageOptions.radius}
                    onChange={(e) => {
                      setCoverageOptions({
                        ...coverageOptions,
                        radius: parseInt(e.target.value)
                      });
                    }}
                  />
                </div>
                <div className="flex-column" style={{ gap: "3px" }}>
                  <span>Coverage Shape</span>
                  <Select
                    value={coverageOptions.coverageType}
                    onChange={(value) => {
                      setCoverageOptions({ ...coverageOptions, coverageType: value });
                    }}
                    options={[
                      { value: "Bubble", label: "Bubble" },
                      { value: "WellOutline", label: "Well Outline" }
                    ]}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </BaseMenu>
  );
}

export const MapVisOptionItem = styled.div`
  padding: 5px 20px;

  .ant-slider {
    min-width: 140px;
  }

  .ant-select {
    min-width: 150px;
  }

  label {
    font-weight: var(--fontWeightMedium);
    color: #9b9b9b;
  }

  .ant-input {
    max-width: 150px;
  }

  label.active {
    color: var(--color-text);
    font-weight: var(--fontWeightBold);
  }

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: space-between;

  .removable-property {
    height: 32px;
    background-color: #d9e1e266;
    padding: 5px 8px;
    border-radius: 4px;
    min-width: 140px;
    position: relative;
    justify-content: space-between;

    .icon-toggle {
      svg {
        color: #b5b5b5;
        transition: color var(--duration);
      }

      &:hover {
        svg {
          color: var(--color-danger);
        }
      }
    }
  }
`;

const emptyFeatureCollection = {
  type: "FeatureCollection",
  features: []
} as GeoJSON.FeatureCollection;
