/**
 * Capitalize the first letter of a string
 *
 * @param {string} str string
 * @returns {string} string with it's first letter capitalized
 */
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function stringToEnum<T>(enumType: T, value: string): T[keyof T] | undefined {
  return (Object.values(enumType) as unknown as string[]).includes(value)
    ? (value as unknown as T[keyof T])
    : undefined;
}
