import { Switch } from "antd";
import styled from "styled-components/macro";

import XdaDataPicker from "../../XdaDataPicker";
import { updateXDASettings, useVisState } from "../../context";
import { XdaOptionLabel, XdaOptionsWrapper } from "./XdaOptionsLayout";

const XdaDataOptions = () => {
  const [{ xda }, visDispatch] = useVisState();
  const { showData } = xda.settings;

  // sync option changes with context
  const handleSettingChange = (key) => (value) => {
    const nextSettings = { ...xda.settings, [key]: value };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <XdaOptionsWrapper width={"400px"}>
      <OptionItem>
        <XdaOptionLabel>Show Data</XdaOptionLabel>
        <Switch
          size="small"
          checked={showData}
          onChange={handleSettingChange("showData")}
        />
      </OptionItem>
      {showData && (
        <XdaDataPicker
          onChange={(fields) => {
            const nextSettings = { ...xda.settings, dataFields: fields };
            updateXDASettings(visDispatch, nextSettings);
          }}
        />
      )}
    </XdaOptionsWrapper>
  );
};

export default XdaDataOptions;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;

  .ant-slider {
    width: 80px;
  }

  padding: 2px 0;
`;
