// base chart-style settings
export const WellSurveyAccess = "Survey";
export const FracAccess = "Frac";
export const cookies = {
  LAST_LOGGED_IN: "last-logged-in",
  CUSTOM_COLORS: "custom-colors",
  LOGIN_EMAIL: "login-email",
  GEOSCOUT_STATUS: "geoscout-status"
};

export const MY_FOCUS_FIELDS = "My Fields";
export const ORG_FOCUS_FIELDS = "Organization Fields";
export const ORG_GEO_FIELDS = "Organization Geo Fields";
export const MCDANIEL_SEARCH_FOLDER_NAME = "McDaniel Research";
export const WORKING_FORECAST_FOLDER_NAME = "Working Forecasts";
export const MCDANIEL_NAME = "McDaniel";
export const NOVI_SEARCH_FOLDER_NAME = "Novi";
export const FORECAST_KEY = "fcst";
export const SYNC_KEY = "sync";
export const USER_ARPS = "USERARPS";
export const TYPE_WELLS = "TYPEWELLS";
export const ValNavTypeWellFolderName = "ValNav~~~";
export type ForecastActivityType = typeof USER_ARPS | typeof TYPE_WELLS;
export const NOVI_FOLDER_ID = "-2";
export const MCDANIEL_FOLDER_ID = "-1";
export const WORKING_FORECAST_FOLDER_ID = "-1.5";
export const X_MILES_LOCAL_STORAGE_KEY = "xMiles";

export const COLOR_PALETTES = [
  {
    name: "Cold To Hot",
    id: "-1",
    colors: [
      "rgb(41, 204, 148)",
      "rgb(41, 84, 204)",
      "rgb(147, 41, 204)",
      "rgb(204, 41, 57)"
    ]
  },
  {
    name: "Viridis",
    id: "-3",
    colors: ["rgb(68,1,84)", "rgb(54,90,140)", "rgb(40,174,127)", "rgb(233,228,39)"]
  },
  {
    name: "Cool",
    id: "-3",
    colors: [
      "rgb(0,255, 255)",
      "rgb(72, 183, 255)",
      "rgb(164, 91, 255)",
      "rgb(247, 7, 255)"
    ]
  },
  {
    name: "Hot",
    id: "-3",
    colors: ["rgb(10, 0, 0)", "rgb(209, 0, 0)", "rgb(255, 175, 0)", "rgb(255, 255, 193)"]
  },
  {
    name: "Jet",
    id: "-3",
    colors: ["rgb(0,0,127)", "rgb(0, 160, 255)", "rgb(234, 243, 13)", "rgb(159, 7, 0)"]
  },
  {
    name: "Portland",
    id: "-3",
    colors: ["rgb(12,51,131)", "rgb(10,136,186)", "rgb(242,143,56)", "rgb(217,30,30)"]
  }
];

export const COMPATIBLE_COLORMAP_NAMES = [
  "viridis",
  "plasma",
  "inferno",
  "magma",
  "cividis",
  "turbo",
  "jet",
  "hsv",
  "hot",
  "spring",
  "portland",
  "summer",
  "autumn",
  "winter",
  "bone",
  "copper",
  "greys",
  "greens",
  "blues",
  "reds",
  "pink"
];

export const base_chart_style = {
  wellStyles: [
    {
      color: 4280629062,
      title: "Well/Group 1",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4288986306,
      title: "Well/Group 2",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4283651674,
      title: "Well/Group 3",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4289117655,
      title: "Well/Group 4",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4292066445,
      title: "Well/Group 5",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4291732815,
      title: "Well/Group 6",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4290085200,
      title: "Well/Group 7",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4292642355,
      title: "Well/Group 8",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4288959048,
      title: "Well/Group 9",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4292462750,
      title: "Well/Group 10",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4288330157,
      title: "Well/Group 11",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4288170070,
      title: "Well/Group 12",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4289931345,
      title: "Well/Group 13",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4286519309,
      title: "Well/Group 14",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4287867392,
      title: "Well/Group 15",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4284035861,
      title: "Well/Group 16",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4293999123,
      title: "Well/Group 17",
      dashed: false,
      thickness: 1.25
    },
    {
      color: 4280495126,
      title: "Well/Group 18",
      dashed: false,
      thickness: 1.25
    }
  ],
  scatterSize: 8,
  averageStyle: {
    color: 4294052899,
    title: "Average",
    dashed: false,
    thickness: 3
  },
  selectedStyle: {
    color: 4278190080,
    title: "Selected well",
    dashed: false,
    thickness: 3
  },
  notAvailableStyle: {
    color: 4292269782,
    title: "N/A",
    dashed: false,
    thickness: 1.25
  },
  referenceCurvedStyles: [
    {
      title: "Reference Curve 1",
      color: 4283914071,
      dashed: false,
      thickness: 3
    },
    {
      title: "Reference Curve 2",
      color: 4283914071,
      dashed: false,
      thickness: 3
    },
    {
      title: "Reference Curve 3",
      color: 4283914071,
      dashed: false,
      thickness: 3
    },
    {
      title: "Reference Curve 4",
      color: 4283914071,
      dashed: false,
      thickness: 3
    },
    {
      title: "Reference Curve 5",
      color: 4283914071,
      dashed: false,
      thickness: 3
    },
    {
      title: "Reference Curve 6",
      color: 4283914071,
      dashed: false,
      thickness: 3
    }
  ]
};
export const screenShotSizes = {
  HR1: { width: 1152, height: 681 },
  S1: { width: 1184, height: 938 },
  HR2: { width: 2416, height: 956 },
  VR1: { width: 774, height: 938 }
};

// thickness items for chart-style settings
export const thickness_items_list = [
  { label: "Thin", thickness: 0.75, dashed: false },
  { label: "Normal", thickness: 1.25, dashed: false },
  { label: "Medium", thickness: 2, dashed: false },
  { label: "Semi Bold", thickness: 3, dashed: false },
  { label: "Bold", thickness: 4, dashed: false },
  { label: "Extra Bold", thickness: 5, dashed: false },
  { label: "Heavy", thickness: 6, dashed: false },
  { label: "Thin", thickness: 0.75, dashed: true },
  { label: "Normal", thickness: 1.25, dashed: true },
  { label: "Medium", thickness: 2, dashed: true },
  { label: "Semi Bold", thickness: 3, dashed: true },
  { label: "Bold", thickness: 4, dashed: true },
  { label: "Extra Bold", thickness: 5, dashed: true },
  { label: "Heavy", thickness: 6, dashed: true }
];

// scatter-size list for chart-style settings
export const scatter_size_list = [
  { label: "Thin", size: 2 },
  { label: "Normal", size: 4 },
  { label: "Medium", size: 6 },
  { label: "Semi Bold", size: 8 },
  { label: "Bold", size: 10 },
  { label: "Extra Bold", size: 12 },
  { label: "Heavy", size: 14 }
];
