import { FunctionComponent, ReactNode } from "react";

import { TooltipPlacement } from "antd/es/tooltip";
import styled from "styled-components/macro";

import { Tooltip } from "components/base";

type ToolbarButtonT = {
  testId: string;
  active?: boolean;
  activeIcon?: ReactNode;
  icon: ReactNode;
  isMenuButton?: boolean;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
  overflowLabel?: string;
  tooltipDelay?: number;
  onToggle?: (b: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const ToolbarButton: FunctionComponent<ToolbarButtonT> = ({
  testId,
  active = false,
  activeIcon,
  icon,
  isMenuButton = false,
  tooltipText = undefined,
  overflowLabel = "",
  tooltipPlacement = "topRight",
  onToggle = null,
  ...rest
}) => {
  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <ButtonWrapper>
        <StyledButton
          active={active}
          data-testid={testId}
          data-qa={testId}
          onClick={() => onToggle(!active)}
          {...rest}>
          {active && activeIcon ? activeIcon : icon}
          {overflowLabel}
        </StyledButton>
        {isMenuButton && (
          <MenuButtonIndicator active={active} isGlobalSetting={rest.isGlobalSetting} />
        )}
      </ButtonWrapper>
    </Tooltip>
  );
};

export default ToolbarButton;

const StyledButton = styled.button`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  border-radius: 3px;
  background: ${(props) =>
    props.isGlobalSetting && props.active ? "var(--color-primary)" : "transparent"};
  color: ${(props) =>
    props.isGlobalSetting && props.active
      ? "white"
      : props.active
      ? "var(--color-primary)"
      : "#a2aaad"};
  &:hover {
    color: var(--color-primary);
    background: ${(props) =>
      props.isGlobalSetting && props.active ? "white" : "transparent"};
  }
  border: none;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

const MenuButtonIndicator = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent; /* Adjust triangle size */
  border-bottom: 7px solid;

  border-bottom-color: ${(props) =>
    props.isGlobalSetting && props.active
      ? "white"
      : props.active
      ? "var(--color-primary)"
      : "#a2aaad"};
`;

const ButtonWrapper = styled.div`
  position: relative;

  &:hover ${MenuButtonIndicator} {
    border-bottom-color: ${(props) =>
      props.isGlobalSetting && props.active ? "white" : "var(--color-primary)"};
  }
`;
