import { Icon } from "@mdi/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import { mdiCircleOutline, mdiLasso, mdiSquareOutline } from "@mdi/js";
import centroid from "@turf/centroid";
import { Polygon } from "@turf/helpers";
import { Dropdown } from "antd";
import classnames from "classnames";
import { setMapExtent, setResetPolygonFilter, setViewLock } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";

import FilterSelectedWell from "../FilterSelectedWell";
import { useMapContext } from "../hooks/useMapContext";
import { useMapDispatch } from "../hooks/useMapDispatch";
import { getFeaturesIntersectingPolygon } from "../utils/mapboxHelper";

export interface LassoSelectionComponentModel {
  drawRef;
  portalRef?: React.MutableRefObject<HTMLDivElement>;
}

const LassoSelection: React.FC<LassoSelectionComponentModel> = ({
  portalRef,
  drawRef
}) => {
  const { mapbox, isFullscreen } = useMapContext();
  const [selectionPolygon, setSelectionPolygon] = useState<GeoJSON.Polygon>(undefined);
  useHotkeys(
    "esc",
    () => {
      cancelPolygonSelection();
    },
    {},
    [mapbox]
  );
  const resetPolygonFilter = useSelector(
    (state: RootState) => state.filter.resetPolygonFilter
  );
  const [selectionCentroid, setSelectionCentroid] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isLassoSelect, setLassoSelect] = useState(false);
  const dispatch = useDispatch();
  const mapDispatch = useMapDispatch();
  const { isActive: isGeomBinOpen, isEditing: isGeomBinEditing } = useGeomBinContext();

  const isGeomBinEditingRef = useRef(isGeomBinEditing);
  const isGeomBinOpenRef = useRef(isGeomBinOpen);

  const [selectedShape, setSelectedShape] = useState("draw_polygon");

  useEffect(() => {
    isGeomBinEditingRef.current = isGeomBinEditing;
    isGeomBinOpenRef.current = isGeomBinOpen;
  }, [isGeomBinEditing, isGeomBinOpen]);

  const updateSelectionCentroid = useCallback(
    (mapbox, polygon) => {
      if (!polygon || isGeomBinEditingRef.current || isGeomBinOpenRef.current) {
        setSelectionCentroid(null);
        mapDispatch({
          payload: {
            isPolygonFilterControlVisible: false
          }
        });
        return;
      }

      if (polygon.type === "Feature") {
        polygon = polygon.geometry;
      }
      const c = centroid(polygon);
      const [lat, lng] = c.geometry.coordinates;
      const coordinates = mapbox.project([lat, lng]);
      mapDispatch({
        payload: {
          isPolygonFilterControlVisible: true
        }
      });
      setSelectionCentroid(coordinates);
    },
    [isGeomBinEditing, isGeomBinOpen, mapDispatch]
  );

  function cancelPolygonSelection() {
    if (!drawRef?.current) {
      return;
    }
    try {
      drawRef.current.deleteAll();
      updateSelectionCentroid(mapbox, null);
      mapDispatch({
        payload: {
          selectedFeatures: []
        }
      });
      setLassoSelect(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  async function onPolygonSelection(geom: GeoJSON.Polygon) {
    if (!mapbox) {
      return;
    }
    const isPolygon = geom.type === "Polygon";
    const { selectedWells, selectedFeatures } = await getFeaturesIntersectingPolygon(
      mapbox,
      geom
    );
    if (!selectedWells) {
      return;
    }
    if (isPolygon) {
      //only show selection controls when polygon selection
      updateSelectionCentroid(mapbox, geom);
      mapDispatch({
        payload: {
          selectedFeatures
        }
      });
    }
  }

  async function onDrawEnd(e) {
    //calls when finished drawing
    if (e.features.length > 0) {
      const feature = e.features[0];

      const geom = feature.geometry as GeoJSON.Polygon;
      if (geom?.type !== "Polygon") {
        return;
      }
      await onPolygonSelection(geom);
      setSelectionPolygon(geom);
    }
  }

  useEffect(() => {
    if (!mapbox || isGeomBinEditingRef.current || isGeomBinOpenRef.current) {
      return;
    }

    mapbox.on("draw.create", onDrawEnd);
    mapbox.on("draw.update", onDrawEnd);
    return () => {
      mapbox.off("draw.create", onDrawEnd);
      mapbox.on("draw.update", onDrawEnd);
    };
  }, [mapbox, isGeomBinOpen, isGeomBinEditing]);

  useEffect(() => {
    if (!portalRef) {
      return;
    }
  }, [portalRef]);

  useEffect(() => {
    if (!resetPolygonFilter) {
      return;
    }
    if (!mapbox) return;

    const bounds = mapbox.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const xmin = sw.lng;
    const ymin = sw.lat;
    const xmax = ne.lng;
    const ymax = ne.lat;
    const polygon: Polygon = {
      type: "Polygon",
      coordinates: [
        [
          [xmin, ymin],
          [xmax, ymin],
          [xmax, ymax],
          [xmin, ymax],
          [xmin, ymin]
        ]
      ]
    };
    dispatch(setViewLock(false));
    dispatch(setMapExtent(polygon));
    dispatch(setResetPolygonFilter(false));
  }, [dispatch, resetPolygonFilter]);

  function onPolygonFilter() {
    cancelPolygonSelection();
    setSelectionCentroid(null);
    setLassoSelect(false);
    dispatch(setResetPolygonFilter(false));
    dispatch(setViewLock(true));
  }

  function onExcludePolygonFilter() {
    cancelPolygonSelection();
    setSelectionCentroid(null);
    setLassoSelect(false);
    dispatch(setResetPolygonFilter(false));
    dispatch(setViewLock(true));
  }

  if (!mapbox || !portalRef?.current || isGeomBinOpen) {
    return <></>;
  }

  const containerClassNames = classnames("icon-toggle lasso-toggle", {
    isActive: isLassoSelect
  });
  const handleDropdownSelect = (shape) => {
    cancelPolygonSelection();
    if (selectedShape === shape && isLassoSelect) {
      setLassoSelect(false);
      setSelectedShape("");
      setDropdownVisible(false);
      return;
    }
    setSelectedShape(shape);
    setLassoSelect(true);
    setDropdownVisible(false);
    drawRef.current.changeMode(shape);
  };

  const toggleLasso = () => {
    cancelPolygonSelection();
    if (isLassoSelect) {
      setLassoSelect(false);
    } else {
      setLassoSelect(true);
      drawRef.current.changeMode(selectedShape);
    }
  };

  const renderIcon = () => {
    let path;
    switch (selectedShape) {
      case "draw_polygon":
        path = mdiLasso;
        break;
      case "draw_circle":
        path = mdiCircleOutline;
        break;
      case "draw_rectangle":
        path = mdiSquareOutline;
        break;
      default:
        path = mdiLasso;
        break;
    }

    return <Icon path={path} className="activity-action-icon" size={1} />;
  };

  const handleDropDownOpen = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <div>
        <div
          className={`BaseTooltip item`}
          data-tip={!isDropdownVisible ? "Lasso Selection" : null}>
          <StyledButton
            role="button"
            aria-pressed={isLassoSelect}
            className={containerClassNames}
            disabled={false}
            size={36}
            tabIndex={0}
            onClick={toggleLasso}>
            {renderIcon()}
          </StyledButton>
          <DropDownTrigger onClick={handleDropDownOpen}></DropDownTrigger>
        </div>
        <Dropdown
          overlayClassName="lasso-dropdown"
          menu={{
            onClick: (v) => handleDropdownSelect(v.key),
            selectedKeys: [selectedShape],
            items: [
              {
                key: "draw_polygon",
                icon: <Icon path={mdiLasso} className="activity-action-icon" size={1} />,
                label: "Polygon"
              },
              {
                key: "draw_rectangle",
                icon: (
                  <Icon
                    path={mdiSquareOutline}
                    className="activity-action-icon"
                    size={1}
                  />
                ),
                label: "Square"
              },
              {
                key: "draw_circle",
                icon: (
                  <Icon
                    path={mdiCircleOutline}
                    className="activity-action-icon"
                    size={1}
                  />
                ),
                label: "Circle"
              }
            ]
          }}
          trigger={["click"]}
          open={isDropdownVisible}
          onOpenChange={(visible) => setDropdownVisible(visible)}>
          <StyledDiv />
        </Dropdown>
      </div>
      {createPortal(
        <FilterSelectedWell
          onFilter={onPolygonFilter}
          onExcludeFilter={onExcludePolygonFilter}
          polygon={selectionPolygon}
          location={selectionCentroid}
          cancelPolygon={cancelPolygonSelection}
          isFullscreen={isFullscreen}
        />,
        portalRef.current
      )}
    </>
  );
};

const DropDownTrigger = styled.button`
  position: absolute;
  bottom: 0;
  opacity: 0;
  right: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  width: 10px;
  height: 10px;
`;

const StyledDiv = styled.div`
  width: 0;
  height: 0;
`;
const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: var(--color-text);

  background-color: transparent;
  border: none;

  position: relative;
  padding: var(--space-2);
  transition: color var(--duration-short) var(--ease);
  user-select: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export default LassoSelection;
