import { FC, useEffect, useState } from "react";

import { Table } from "antd";
import { ForecastMonth } from "arps_wasm";
import { ICheckedForecast } from "store/features";
import styled from "styled-components";

import { useFetchForecastArray } from "components/arps/hooks/useFetchForecastArray";

interface ForecastArrayContentProps {
  selectedTw: ICheckedForecast;
}
const roundText = (text: string) => {
  if (!text) {
    return "";
  }
  return <span>{parseFloat(text).toFixed(1)}</span>;
};
const forecastArrayColumns = [
  {
    title: "Date",
    dataIndex: "prodDate",
    render: (text: string) => {
      const date = new Date(text);
      return (
        <span>{`${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}`}</span>
      );
    }
  },
  {
    title: "Oil (bbl)",
    dataIndex: "oil_bbl",
    render: roundText
  },
  {
    title: "Condensate (bbl)",
    dataIndex: "cond_bbl",
    render: roundText
  },
  {
    title: "Gas (mcf)",
    dataIndex: "gas_mcf",
    render: roundText
  },
  {
    title: "Water (bbl)",
    dataIndex: "water_bbl",
    render: roundText
  }
];

export const ForecastArrayContent: FC<ForecastArrayContentProps> = ({ selectedTw }) => {
  const { data } = useFetchForecastArray({ ids: [selectedTw?.id] });
  const [forecastArray, setForecastArray] = useState<ForecastMonth[]>([]);
  useEffect(() => {
    if (!data || !selectedTw) {
      return;
    }
    if (selectedTw.id in data.forecastArray) {
      setForecastArray(data.forecastArray[selectedTw.id]);
    }
  }, [data]);

  return (
    <ForecastArrayContentRoot data-testid={"forecast-array-content-root"}>
      <Table
        dataSource={forecastArray}
        columns={forecastArrayColumns}
        pagination={false}></Table>
    </ForecastArrayContentRoot>
  );
};

const ForecastArrayContentRoot = styled.div`
  padding: 5px;
`;
