import styled from "styled-components/macro";

export const ThreeDVOptionsWrapper = styled.div`
  min-width: 280px;
  border-radius: var(--border-radius);

  .ant-card-body {
    padding: 5px;
  }

  .ant-select {
    min-width: 180px;
    width: 180px;
    align-self: flex-end;
  }
`;

export const ThreeDVOptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;

  .ant-slider {
    width: 80px;
    margin: 5px 0;
  }

  .ant-select {
    min-width: 140px;
  }

  .ant-input {
    height: 25px;
    max-width: 80px;
  }
`;

export const ThreeDVOptionLabel = styled.label`
  min-width: 120px;
`;
