export const ATTENTION_POLYGON_LAYER = "Attention Polygon";
export const ATTENTION_WELL_LAYER = "Attention Well";
export const BASE_WELL_LAYER = "Base Well";
export const BUBBLE_LAYER = "bubble";
export const COVERAGE_LAYER = "coverage";
export const COMPLETION_FRAC_LAYER = "Frac Line";
export const COMPLETION_PERF_LAYER = "Perforation Points";
export const EXPLOITED_LAYER = "Exploited Area";
export const GEO_MAP_LAYER = "Geo Map";
export const HIGHLIGHTED_PLAY_LAYER = "Highlighted Play";
export const HIGHLIGHTED_WELL_LAYER = "Highlighted Well";
export const HIGHLIGHTED_WELL_POINT_LAYER = "Highlighted Well Point";
export const IPDB_LAYER = "IPDB";
export const IPDB_CONTROL_WELLS_LAYER = "IPDB Control Wells";
export const LSD_LABEL_LAYER = "LSD Label";
export const LSD_LAYER = "LSD";
export const SECTION_LABEL_LAYER = "Section Label";
export const SECTION_LAYER = "Section";
export const SELECTED_SINGLE_WELL_LAYER = "Selected Single Well";
export const SELECTED_WELL_LAYER = "Selected Well";
export const SELECTED_WELL_POINT_LAYER = "Selected Well Point";
export const POLYGON_BINS_LAYERS = "Polygon Bins";
export const POLYGON_BINS_MY_FIELDS_LAYERS = "My Fields";
export const POLYGON_BINS_ORG_FIELDS_LAYERS = "Organization Fields";
export const SHAPEFILE_LABELS_LAYER = "Shapefile Details Label";
export const SHAPEFILE_NAME_LABELS_LAYER = "Shapefile Name Label";
export const SYNC_WELL_LAYER = "Sync Well";
export const TOWNSHIP_LABEL_LAYER = "Township Label";
export const TOWNSHIP_LAYER = "Township";
export const TYPE_LOG_LAYER = "Type Log";
export const TYPE_LOG_VERTICAL_LAYER = "Type Log Vertical";
export const NTS_BLOCK_LABEL_LAYER = "NTS Block Label";
export const UNEXPLOITED_LAYER = "Unexploited Area";
export const WELL_LABEL_LAYER = "Wells Label";
export const WELL_LAYER = "Wells";

export const FACILITY_LABEL_LAYER = "Facility Label";
export const FACILITY_LAYER = "Facility";
export const WELL_LAYER_POINT = "Wells Vert";
export const XDA_INTERCEPT_LAYER = "XDA Line";
export const XDA_LABEL = "XDA Label";
export const XDA_WELL_INTERCEPT_POINT = "XDA Well Points";

export const dependantLayers = [
  SELECTED_WELL_POINT_LAYER, // Dependant on SELECTED_WELL_LAYER
  // SYNC_WELL_LAYER, // Dependant on SELECTED_WELL_LAYER
  SELECTED_SINGLE_WELL_LAYER, // Dependant on SELECTED_WELL_LAYER
  WELL_LAYER_POINT, // Dependant on WELL_LAYER
  TYPE_LOG_VERTICAL_LAYER // Dependant on TYPE_LOG_LAYER
];
