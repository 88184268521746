// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiAlert } from "@mdi/js";
import { Typography } from "antd";
import styled from "styled-components";

import { useUserSettingMutation } from "hooks";

import { BaseSlideToggle } from "components/base";
import { DEFAULT_MIDSTREAM_CHART_BACKGROUND_COLOUR } from "components/user-settings/constants/userSetting.constants";
import { useUserContext } from "components/user/context";
import { updateSettingMidstreamChartBackgroundColour } from "components/user/context/reducer/UserReducer";
import { useUserSettings } from "components/user/hooks";

import { MidstreamChartBackgroundColourType } from "../../models";

const { Title } = Typography;

const MidstreamChartBackgroundColour = () => {
  // hooks
  const [, dispatch] = useUserContext();
  const { midstreamChartBackgroundColour } = useUserSettings();
  const mutation = useUserSettingMutation<MidstreamChartBackgroundColourType>(
    DEFAULT_MIDSTREAM_CHART_BACKGROUND_COLOUR
  );

  const [useGreyBackgroundColour, setUseGreyBackgroundColour] = useState<boolean>(
    midstreamChartBackgroundColour?.useGreyBackgroundColour
  );
  const [updateError, setUpdateError] = useState("");

  const handleMidstreamChartBackgroundColourChange = useCallback(
    (useMidstreamChartBackgroundColour: boolean) => {
      mutation.mutate({
        id: midstreamChartBackgroundColour?.id,
        useGreyBackgroundColour: useMidstreamChartBackgroundColour
      });
      setUseGreyBackgroundColour(useMidstreamChartBackgroundColour);
    },
    [midstreamChartBackgroundColour?.id]
  );

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;
    updateSettingMidstreamChartBackgroundColour(dispatch, {
      id: mutation.data.id,
      useGreyBackgroundColour: mutation.data.useGreyBackgroundColour
    });
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update midstream chart background colour setting.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  return (
    <Wrapper>
      <Title level={5} type="secondary">
        Midstream Chart Indicator
      </Title>
      <ToggleWrapper>
        <BaseSlideToggle
          checked={useGreyBackgroundColour}
          classic
          onChange={(val) => {
            handleMidstreamChartBackgroundColourChange(val);
          }}
        />
      </ToggleWrapper>
      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </Wrapper>
  );
};

export default MidstreamChartBackgroundColour;

//Styled  Components
const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  .anticon-loading {
    font-size: 18px;
    color: var(--color-primary);
  }
`;

const ToggleWrapper = styled.div`
  position: relative;
  top: 3.5px;
`;

const ErrorContainer = styled.div`
  padding-top: 8px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;
