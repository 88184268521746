// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import useResizeObserver from "@react-hook/resize-observer";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useMutation as rqUseMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateNewFolder,
  Folder,
  FolderSpecial,
  Info,
  Refresh
} from "@material-ui/icons";
import { mdiTrendingDown } from "@mdi/js";
import { Button, Checkbox, Dropdown, MenuProps, Switch, Tooltip, Tree } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import {
  ForecastActivityType,
  MCDANIEL_FOLDER_ID,
  MCDANIEL_SEARCH_FOLDER_NAME,
  NOVI_FOLDER_ID,
  NOVI_SEARCH_FOLDER_NAME,
  TYPE_WELLS,
  USER_ARPS,
  WORKING_FORECAST_FOLDER_ID,
  WORKING_FORECAST_FOLDER_NAME
} from "constants/settings.constants";
import debounce from "lodash/debounce";
import _unique from "lodash/uniqBy";
import {
  ICheckedForecast,
  SPECIAL_FOLDERS_ID,
  SPECIAL_FOLDERS_TYPE,
  getSpecialFolders,
  setCheckedForecasts,
  setCheckedGlobalForecasts,
  setCheckedGlobalTypeWells,
  setCheckedKeys,
  setExpandedKeys,
  setFilteredForecastFolders,
  setForecastFolders,
  setForecastMode,
  setHasViewItemsChanged,
  setNormalizeTypeWell,
  setSelectedTypeWell,
  setUseForecastStartDate,
  setUseMcDanielForecasts,
  setUseNoviForecasts,
  setUseWorkingForecasts,
  setUserForecastSettings
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { getFilesFromEvent } from "utils/upload/getFilesFromEvent";

import { useUser } from "hooks";
import useBetaFeatures from "hooks/useBetaFeatures";

import { FOLDERS, UPDATE_FOLDER_NAME } from "api/userArps";
import {
  UpdateForecastResponse,
  UploadForecastRequest,
  ValNavFolderRequest,
  saveValNavFolder,
  updatePerformanceData,
  uploadForecasts
} from "api/userForecasts";

import {
  ForecastFolder,
  ForecastFolderItem,
  UserArpsFile,
  UserArpsItem
} from "models/UserArpsModel";

import { UserArpsContainer } from "components/activity/shared";
import {
  useForecastFolderFetcher,
  useForecastFolderUpdater,
  useTypeWellResetter,
  useTypeWellSaver,
  useTypeWellUpdater,
  useValNavTypeWellUpdater
} from "components/arps/hooks";
import { getTitle } from "components/arps/utils/getTitle";
import { BaseDivider, BaseInput, Popover } from "components/base";
import { IconSpinner } from "components/icons";
import { DEFAULT_DECLINE_TYPE_SETTING } from "components/user-settings/constants/userSetting.constants";
import { useUserSettings } from "components/user/hooks";

import useUndoRedo from "../../arps/hooks/useUndoRedo";
import { useProjectContext } from "../projects/context";
import { useSelectedProject } from "../projects/hooks";
import ExportForecastsButton, { WellSource } from "./ExportForecastsButton";
import FolderNodeActions from "./FolderNodeActions";
import ForecastDetails from "./ForecastDetails";
import ForecastModeSelect from "./ForecastModeSelect";
import ForecastNodeActions from "./ForecastNodeActions";
import "./ProjectForecasts.scss";
import { useTreeData } from "./hooks/useTypeWellTree";
import { useMoveForecast } from "./mutations/useMoveForecast";
import { getNewFolderName } from "./utils";

export interface ProjectForecastsModel {
  type: ForecastActivityType;
  location: "Widget" | "Activity";
  widgetProps?: WidgetProps;
}

interface WidgetProps {
  setWidgetActiveTabKey: () => void;
}

export interface SaveResult {
  saveForecast: string;
}

export default function ProjectForecasts({
  type,
  location,
  widgetProps
}: ProjectForecastsModel) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const { hasFeature } = useBetaFeatures();

  const userSettingsOpen = useSelector(
    (state: RootState) => state.userSetting.userSettingsOpen
  );

  const normalizeTypeWell = useSelector(
    (state: RootState) => state.app.normalizeTypeWell
  );
  const forecastMode = useSelector((state: RootState) => state.app.forecastMode);
  const useForecastStartDate = useSelector(
    (state: RootState) => state.app.useForecastStartDate
  );
  const userForecastSettings = useSelector(
    (state: RootState) => state.app.userForecastSettings
  );

  const checkedTypeWellKeys = useSelector(
    (state: RootState) => state.arps.checkedTypeWellKeys
  );
  const checkedForecastKeys = useSelector(
    (state: RootState) => state.arps.checkedForecastKeys
  );
  const checkedTypeWells = useSelector((state: RootState) => state.arps.checkedTypeWells);
  const checkedForecastsState = useSelector(
    (state: RootState) => state.arps.checkedForecasts
  );
  const expandedTypeWellKeys = useSelector(
    (state: RootState) => state.arps.expandedTypeWellKeys
  );
  const expandedForecastKeys = useSelector(
    (state: RootState) => state.arps.expandedForecastKeys
  );
  const typeWellFolders = useSelector((state: RootState) => state.arps.typeWellFolders);
  const forecastFoldersState = useSelector(
    (state: RootState) => state.arps.forecastFolders
  );
  const hasTypeWellSaveSucceeded = useSelector(
    (state: RootState) => state.arps.hasTypeWellSaveSucceeded
  );
  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);
  const useMcDanielForecasts = useSelector(
    (state: RootState) => state.arps.useMcDanielForecasts
  );
  const useNoviForecasts = useSelector((state: RootState) => state.arps.useNoviForecasts);
  const useWorkingForecasts = useSelector(
    (state: RootState) => state.arps.useWorkingForecasts
  );

  const checkedKeys = type === TYPE_WELLS ? checkedTypeWellKeys : checkedForecastKeys;

  const checkedForecasts = type === TYPE_WELLS ? checkedTypeWells : checkedForecastsState;

  const expandedKeys = type === TYPE_WELLS ? expandedTypeWellKeys : expandedForecastKeys;

  const forecastFolders = type === TYPE_WELLS ? typeWellFolders : forecastFoldersState;

  const [updateFolderName] = useMutation(UPDATE_FOLDER_NAME);

  const valnavFolderMutation = rqUseMutation(
    "vnfolder",
    (data: ValNavFolderRequest) => {
      return saveValNavFolder(data);
    },
    {
      onSuccess: async () => {
        await refetch();
      }
    }
  );

  const { isReadonly } = useUser();
  const { permissions: projectPermissions, selectedViewId } = useProjectContext();
  const { selectedProject: project } = useSelectedProject();

  const [searchValue, setSearchValue] = useState("");
  const [height, setHeight] = useState(100);
  const ref = useRef(null);
  const [nameField, setNameField] = useState("");
  const [highlightedFolder, setHighlightedFolder] = useState(null);
  const [nameChangeError, setNameChangeError] = useState(false);

  const {
    loading,
    data: foldersData,
    refetch
  } = useQuery(FOLDERS, {
    variables: {
      req: {
        projectId: project?.projectId,
        type: type
      }
    },
    skip: !project?.projectId
  });

  const updatePerformanceDataMutation = rqUseMutation(
    (folderName: string) => {
      return updatePerformanceData(folderName);
    },
    {
      onError: (err: AxiosError) => {
        toast.error(err?.response?.data);
      }
    }
  );

  const { declineType: declineTypeSetting } = useUserSettings();

  const declineType = declineTypeSetting?.decline ?? DEFAULT_DECLINE_TYPE_SETTING;

  const undoRedoManager = useUndoRedo();

  const { onSaveTypeWell } = useTypeWellSaver(undoRedoManager);
  const { changeSelectedTypeWell } = useTypeWellUpdater(undoRedoManager);
  const { resetTypeWell } = useTypeWellResetter(type, undoRedoManager);

  const { updateTypeWellDataWithParentNode } = useForecastFolderUpdater();
  const memoizedUpdateTypeWellDataWithParentNode = useCallback(
    updateTypeWellDataWithParentNode,
    []
  );

  const { onUpdateValNavTypeWell, valnavError, setValnavError } =
    useValNavTypeWellUpdater(type);

  const memoizedSetValnavError = useCallback(setValnavError, [type]);

  const { getForecastFoldersFromFoldersData } = useForecastFolderFetcher(type);
  const memoizedGetForecastFoldersFromFoldersData = useCallback(
    getForecastFoldersFromFoldersData,
    [type]
  );

  const moveTypeWell = useMoveForecast(type);
  const { treeData } = useTreeData(type);
  const onTreeExpand = useCallback(
    (expandedKeysValue: string[]) => {
      dispatch(setExpandedKeys({ type: type, expandedKeys: expandedKeysValue }));
    },
    [dispatch, type]
  );

  const treeRef = useRef(null);
  useHotkeys(
    "ctrl+s",
    () => {
      onSaveTypeWell();
    },
    [selectedTypeWell]
  );
  useHotkeys(
    "ctrl+z",
    () => {
      if (undoRedoManager.canUndo) {
        undoRedoManager.undo();
      }
    },
    [undoRedoManager.undo]
  );
  useHotkeys(
    "ctrl+y",
    () => {
      if (undoRedoManager.canRedo) {
        undoRedoManager.redo();
      }
    },
    [undoRedoManager.redo]
  );
  useHotkeys(
    "ctrl+shift+z",
    () => {
      if (undoRedoManager.canRedo) {
        undoRedoManager.redo();
      }
    },
    [undoRedoManager.redo]
  );
  useHotkeys(
    "r",
    () => {
      onUpdateValNavTypeWell(selectedTypeWell);
    },
    [selectedTypeWell]
  );
  useHotkeys(
    "esc",
    () => {
      resetTypeWell();
    },
    [selectedTypeWell]
  );

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!foldersData) {
      return;
    }

    const userArps = memoizedGetForecastFoldersFromFoldersData(foldersData, type);
    dispatch(setForecastFolders({ type: type, forecastFolders: userArps }));
  }, [foldersData, type, useMcDanielForecasts, userSettingsOpen]);

  useEffect(() => {
    if (!searchValue || searchValue.length == 0) {
      // no filter applies, so return the full forecast folders list.
      dispatch(
        setFilteredForecastFolders({
          type: type,
          filteredForecastFolders: forecastFolders
        })
      );

      return;
    }

    const filtered = [];
    forecastFolders.forEach((f) => {
      const fm = Object.assign({}, f);

      // filter to children that have titles that match the searched text.
      fm.children = f.children.filter(
        (c) => c.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      );

      // only include the folder in the filtered list if it contains any matching children.
      if (fm.children && fm.children.length > 0) {
        filtered.push(fm);
      }
    });

    dispatch(
      setFilteredForecastFolders({
        type: type,
        filteredForecastFolders: filtered
      })
    );
  }, [forecastFolders, searchValue]);

  const uploadMutation = rqUseMutation(
    async (data: UploadForecastRequest) => await uploadForecasts(data),
    {
      onSuccess: async (response: AxiosResponse<UpdateForecastResponse>, requestData) => {
        await refetch({
          req: {
            projectId: project?.projectId,
            type: type
          }
        });
        setError(null);
        memoizedSetValnavError(null);
        setHighlightedFolder(null);
        if (response?.data?.message) {
          setWarning(response.data.message);
        } else {
          setWarning(null);
        }
        updatePerformanceDataMutation.mutate(requestData.folderId);
        dispatch(setHasViewItemsChanged(true));
      },
      onError: (error) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err && err.response?.data) {
          setError(err.response?.data);
          setHighlightedFolder(null);
        } else {
          setError(`Error uploading ${type === USER_ARPS ? "forecast" : "type well"}`);
          setHighlightedFolder(null);
        }
      }
    }
  );

  const onAcceptFiles = useCallback((files) => {
    const filesUpload: UserArpsFile[] = files.map((file) => {
      const userArps = new UserArpsFile();
      userArps.file = file.name;
      userArps.fileUpload = file;
      userArps.source = "valnav";
      userArps.fileType = "upload";
      return userArps;
    });
    return filesUpload;
  }, []);

  const uploadValNav = useCallback(
    async (folderName, folderId, files) => {
      if (isReadonly || !projectPermissions.canEditTypeWells) {
        toast.error("Cannot upload as a read only user");
        return;
      }
      if (files.length === 0) {
        toast.error(`No files selected`);
        return;
      }
      const formData = new FormData();
      for (const file of files) {
        formData.append(file.fileType, file.fileUpload);
      }
      formData.append("source", "valnav");
      formData.append("folderName", folderName);
      const uploadType = type === USER_ARPS ? "forecast" : "type-wells";
      uploadMutation.mutate({
        projectId: project?.projectId,
        uploadType: uploadType,
        folderName: folderName,
        formData: formData,
        folderId: folderId
      });
    },
    [isReadonly, projectPermissions, type, project, uploadMutation]
  );

  const onTypeWellChecked = useCallback(
    (e) => {
      let completeFolders = e.checkedNodes.filter(
        (n) => n.isFolder !== true || (n.isFolder === true && !n.children.length)
      );
      if (searchValue) {
        const children = [].concat(...forecastFolders.map((parent) => parent.children));
        const previouslyCheckedFolders = children.filter((folder) =>
          checkedKeys.includes(folder.id)
        );
        completeFolders = [...completeFolders, ...previouslyCheckedFolders].filter(
          (v, i, a) => a.indexOf(v) === i
        );

        if (!e.checked) {
          if (e.node.isFolder) {
            completeFolders = completeFolders.filter((f) => {
              return (
                f.folderId !== e.node.folderId ||
                f.reserveCategory !== e.node.reserveCategory
              );
            });
          } else {
            completeFolders = completeFolders.filter((f) => f.id !== e.node.id);
          }
        }
      }

      const partialFolders = e.halfCheckedKeys;
      const partialFoldersForecasts =
        partialFolders.length > 0
          ? e.checkedNodes.filter((n) =>
              partialFolders.includes(
                `${n.folderId}${n.reserveCategory ? "_" + n.reserveCategory : ""}`
              )
            )
          : [];
      const checkedItems = [...completeFolders, ...partialFoldersForecasts].filter(
        (v, i, a) => a.indexOf(v) === i
      );
      dispatch(
        setCheckedKeys({ type: type, checkedKeys: checkedItems.map((i) => i.id) })
      );
      const found = checkedItems.map((node) => {
        //eslint-disable-next-line
        const { isChecked, key, ...rest } = node;
        const res = {
          ...rest,
          title: node.title,
          id: node.key, // Use rest.key instead of val.key
          type: node.isFolder ? "folder" : "forecast",
          isFolder: false,
          folderName: undefined
        };
        return res;
      });
      dispatch(setCheckedGlobalTypeWells(found));
      dispatch(setCheckedForecasts({ type: type, checkedForecasts: found }));
    },
    [dispatch, type, searchValue, forecastFolders, checkedKeys]
  );

  const getCheckedSpecialFolders = (
    checkedFolders: string[],
    folderChecked: boolean,
    folderName: string
  ) => {
    const folders = checkedFolders ?? [];
    if (folderChecked && !folders.includes(folderName)) {
      return [...folders, folderName];
    }
    if (!folderChecked) {
      return folders.filter((f) => f !== folderName);
    }
    return folders;
  };

  function isSpecialFolder(node, event, currentCheckedKeys): boolean {
    // Special handling when the selected node is the McDaniel folder.
    const checkIfSpecialFolders = (
      node,
      checkId: SPECIAL_FOLDERS_TYPE,
      dispatchFn: () => void,
      settingsDispatchFn: (value: boolean) => void
    ) => {
      if (node.id === checkId) {
        dispatchFn();
        if (event.checked) {
          currentCheckedKeys = [...checkedKeys, checkId];
        } else {
          currentCheckedKeys = checkedKeys.filter((k) => k !== checkId);
        }
        dispatch(setCheckedKeys({ type: type, checkedKeys: currentCheckedKeys }));
        settingsDispatchFn(event.checked);
        return true;
      }
      return false;
    };
    if (
      checkIfSpecialFolders(
        node,
        MCDANIEL_FOLDER_ID,
        () => dispatch(setUseMcDanielForecasts(event.checked)),
        (checked) =>
          dispatch(
            setUserForecastSettings({
              forecastSourceFolder: userForecastSettings.forecastSourceFolder,
              excludeMcDanielForecasts: !checked,
              reserveCategory: userForecastSettings.reserveCategory,
              excludeNoviForecasts: userForecastSettings.excludeNoviForecasts,
              specialForecastFolders: getCheckedSpecialFolders(
                userForecastSettings.specialForecastFolders,
                checked,
                MCDANIEL_SEARCH_FOLDER_NAME
              )
            })
          )
      )
    ) {
      return true;
    }

    if (
      checkIfSpecialFolders(
        node,
        NOVI_FOLDER_ID,
        () => dispatch(setUseNoviForecasts(event.checked)),
        (checked) =>
          dispatch(
            setUserForecastSettings({
              ...userForecastSettings,
              excludeNoviForecasts: !checked,
              specialForecastFolders: getCheckedSpecialFolders(
                userForecastSettings.specialForecastFolders,
                checked,
                NOVI_SEARCH_FOLDER_NAME
              )
            })
          )
      )
    ) {
      return true;
    }
    if (
      checkIfSpecialFolders(
        node,
        WORKING_FORECAST_FOLDER_ID,
        () => dispatch(setUseWorkingForecasts(event.checked)),
        (checked) =>
          dispatch(
            setUserForecastSettings({
              forecastSourceFolder: userForecastSettings.forecastSourceFolder,
              excludeMcDanielForecasts: userForecastSettings.excludeMcDanielForecasts,
              reserveCategory: userForecastSettings.reserveCategory,
              excludeNoviForecasts: userForecastSettings.excludeNoviForecasts,
              specialForecastFolders: getCheckedSpecialFolders(
                userForecastSettings.specialForecastFolders,
                checked,
                WORKING_FORECAST_FOLDER_NAME
              )
            })
          )
      )
    ) {
      return true;
    }
  }

  const onForecastChecked = useCallback(
    (event) => {
      /*
       * This function is called when a forecast is selected or deselected.
       * Users can choose several uploaded forecast folders and only the last one selected will be used for comparing/trumping.
       * The exception is with the McDaniel and Novi folders.
       */
      let currentCheckedKeys = [...checkedKeys];
      const node = event.node;

      if (isSpecialFolder(node, event, currentCheckedKeys)) {
        return;
      }

      let found = [];
      let reserveCategory: string = null;
      let activeFolderName: string = null;
      if (node.isFolder) {
        if (event.checked) {
          found.push({
            id: node.key, //folder name WITH reserve category at end
            type: "folder",
            folderId: node.folderId, //folder name WITHOUT reserve category at end
            children: node.children,
            title: node.title,
            reserveCategory: node.reserveCategory
          });
          // A new folder was selected, remove all other selections
          currentCheckedKeys = checkedKeys.filter((k) => SPECIAL_FOLDERS_ID.includes(k));
          currentCheckedKeys.push(node.id);
          activeFolderName = node.title;
          reserveCategory = node.reserveCategory;
        } else {
          // Deselect the folder.
          currentCheckedKeys = checkedKeys.filter((k) => k !== node.id);
        }
      } else {
        const activeFolders = event.checkedNodes.filter(
          (n) => !SPECIAL_FOLDERS_ID.includes(n.id) && n.isFolder
        );
        const selectedFolders = [];
        selectedFolders.push(...event.halfCheckedKeys);
        selectedFolders.push(...activeFolders.map((n) => n.id));

        if (selectedFolders.length === 0) {
          // no forecasts are selected
          currentCheckedKeys = [];
        } else if (selectedFolders.length === 1) {
          activeFolderName = node.folderName;
          reserveCategory = node.reserveCategory;
          if (event.checked) {
            // A new forecast was selected in the same folder.
            currentCheckedKeys = [...currentCheckedKeys, node.id];
            found.push(
              ...event.checkedNodes
                .filter((n) => !SPECIAL_FOLDERS_ID.includes(n.id))
                .map((n) => ({
                  id: n.id,
                  type: "forecast",
                  folderId: n.folderId,
                  wellData: n.wellData
                }))
            );
          } else {
            if (activeFolders.length !== selectedFolders.length) {
              // The folder is moving from a fully selected state to a partial
              currentCheckedKeys = event.checkedNodes
                .filter((n) => !SPECIAL_FOLDERS_ID.includes(n.id))
                .map((n) => n.id);
              found.push(
                ...event.checkedNodes
                  .filter((n) => !SPECIAL_FOLDERS_ID.includes(n.id))
                  .map((n) => {
                    return {
                      id: n.id,
                      type: "forecast",
                      folderId: n.folderId,
                      wellData: n.wellData
                    };
                  })
              );
            } else {
              // A forecast was deselected in the folder
              currentCheckedKeys.splice(currentCheckedKeys.indexOf(node.id), 1);
            }
          }
        } else {
          // different folders previously selected, only keep the recent forecast
          currentCheckedKeys = [node.id];
          found.push({
            id: node.id,
            type: "forecast",
            folderId: node.folderId,
            wellData: node.wellData
          });
          activeFolderName = node.folderName;
          reserveCategory = node.reserveCategory;
        }
      }
      // Do not include McDaniel in the checked forecast
      found = found.filter((f) => !SPECIAL_FOLDERS_ID.includes(f.id));

      if (useMcDanielForecasts && currentCheckedKeys.indexOf(MCDANIEL_FOLDER_ID) === -1) {
        currentCheckedKeys.push(MCDANIEL_FOLDER_ID);
      }
      if (useNoviForecasts && currentCheckedKeys.indexOf(NOVI_FOLDER_ID) === -1) {
        currentCheckedKeys.push(NOVI_FOLDER_ID);
      }
      if (
        useWorkingForecasts &&
        currentCheckedKeys.indexOf(WORKING_FORECAST_FOLDER_ID) === -1
      ) {
        currentCheckedKeys.push(WORKING_FORECAST_FOLDER_ID);
      }
      dispatch(setCheckedKeys({ type: type, checkedKeys: currentCheckedKeys }));
      dispatch(setCheckedGlobalForecasts(found));

      dispatch(
        setUserForecastSettings({
          forecastSourceFolder: activeFolderName,
          excludeMcDanielForecasts: !useMcDanielForecasts,
          reserveCategory: reserveCategory,
          excludeNoviForecasts: !useNoviForecasts,
          specialForecastFolders: getSpecialFolders(
            useMcDanielForecasts,
            useNoviForecasts,
            useWorkingForecasts
          )
        })
      );
      //checkedforecast is immutable here because dispatch above
      dispatch(setCheckedForecasts({ type: type, checkedForecasts: found }));
    },
    [
      checkedKeys,
      dispatch,
      type,
      useMcDanielForecasts,
      useNoviForecasts,
      useWorkingForecasts
    ]
  );

  const onCheckChanged = useCallback(
    (ids: string[], e) => {
      if (selectedViewId) {
        dispatch(setHasViewItemsChanged(true));
      }

      if (type === TYPE_WELLS) {
        onTypeWellChecked(e);
      } else {
        onForecastChecked(e);
      }
    },
    [dispatch, selectedViewId, type, onTypeWellChecked, onForecastChecked]
  );

  const updateFolder = useCallback(
    async (folder: ForecastFolder, name: string) => {
      if (!folder) {
        return;
      }

      // Clear name change error on new update request
      setNameChangeError(false);

      if (
        forecastFolders.some(
          (f) => f.folderId !== folder.folderId && f.folderName === name
        )
      ) {
        // If new folder name is duplicated with existing folder, raise error message
        setNameChangeError(true);
        return;
      }

      const input = {
        projectId: project?.projectId,
        folderId: folder.folderId,
        parentId: folder.parentId,
        name: name,
        type: type
      };

      updateFolderName({
        variables: {
          input
        }
      })
        // eslint-disable-next-line
        .then(async () => {
          const copy: ForecastFolder = JSON.parse(JSON.stringify(folder));
          copy.name = name;
          copy.isEdit = false;
          const folders = JSON.parse(JSON.stringify(forecastFolders));
          const idx = folders.findIndex((folder) => folder.key === copy.key);
          if (idx >= 0) {
            folders.splice(idx, 1, copy);
          }
          dispatch(setForecastFolders({ type: type, forecastFolders: folders }));
          await refetch({
            req: {
              projectId: project?.projectId,
              type: type
            }
          });
        })
        .catch(() => {
          setError("Unable to update folder name.");
        });
    },
    [
      project,
      type,
      forecastFolders,
      setNameChangeError,
      setError,
      updateFolderName,
      dispatch
    ]
  );

  useLayoutEffect(() => {
    if (!ref) {
      return;
    }
    setHeight(ref.current.getBoundingClientRect().height);
  }, [ref]);

  useResizeObserver(ref, (client) => {
    if (client.contentRect.height > 0) {
      setHeight(client.contentRect.height);
    }
  });

  async function newFolder() {
    const foldersCopy = JSON.parse(JSON.stringify(forecastFolders));
    foldersCopy.forEach((folder) => {
      folder.isEdit = false;
    });
    const folderName = getNewFolderName(forecastFolders);
    const folderItem: ForecastFolderItem = {
      // Add well list here
      folderId: null,
      projectId: project?.projectId,
      forecasts: null,
      name: folderName,
      folderName: folderName,
      reserveCategory: "",
      parentId: ""
    };
    const folder = new ForecastFolder(folderItem);
    folder.isEdit = true;
    folder.editLocation = location;
    setNameField(folder.folderName);
    dispatch(
      setForecastFolders({ type: type, forecastFolders: [...foldersCopy, folder] })
    );
  }

  const addToCheckedTypeWells = useCallback(
    (existingTws: ICheckedForecast[], newTw: UserArpsItem) => {
      const newTwToAdd: ICheckedForecast = {
        ...newTw,
        title: newTw?.title ?? selectedTypeWell.title,
        id: newTw.key,
        type: newTw.isFolder ? "folder" : "forecast",
        isFolder: false,
        folderName: undefined
      };
      const checkedItems = _unique(existingTws.concat([newTwToAdd]), (item) => item.id);
      const checkedIds = checkedItems.map((i) => i.id);
      dispatch(setCheckedKeys({ type: type, checkedKeys: checkedIds }));
      changeSelectedTypeWell(newTw);
      dispatch(
        setSelectedTypeWell({
          ...selectedTypeWell,
          title: newTw?.title,
          reserveCategory: newTw?.reserveCategory
        })
      );

      setTimeout(() => {
        dispatch(setCheckedForecasts({ type: type, checkedForecasts: checkedItems }));
        changeSelectedTypeWell(newTw);
      }, 200);
    },
    [dispatch, selectedTypeWell, type, changeSelectedTypeWell]
  );

  // eslint-disable-next-line
  const folderNode = useCallback(
    (fNode) => {
      if (fNode.id === MCDANIEL_FOLDER_ID) {
        return (
          <div className="title">
            <div className="title-items">
              <FolderSpecial />
              <span className="title-folder title-text">McDaniel Research</span>

              {!isReadonly && (
                <div className="edit-export">
                  <ExportForecastsButton wellSource={WellSource.McDanielResearch} />
                </div>
              )}
            </div>
          </div>
        );
      }
      if (fNode.id === WORKING_FORECAST_FOLDER_ID) {
        return (
          <div className="title">
            <div className="title-items">
              <FolderSpecial />
              <span className="title-folder title-text">
                {WORKING_FORECAST_FOLDER_NAME}
              </span>
            </div>
          </div>
        );
      }
      if (fNode.id === NOVI_FOLDER_ID) {
        return (
          <div className="title">
            <div className="title-items">
              <FolderSpecial />
              <span className="title-folder title-text">Novi Labs</span>
            </div>
          </div>
        );
      }

      if (fNode.isEdit && fNode.editLocation === location) {
        return (
          <div className="title">
            <div className="title-items">
              <Folder />
              <BaseInput
                data-testid="forecast-folder-name-input"
                value={nameField}
                onChange={setNameField}
                autoFocus
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    //commit and save folder name
                    await updateFolder(fNode, nameField);
                  }
                  if (e.key === "Escape") {
                    await updateFolder(fNode, fNode.folderName);
                  }
                }}
              />
            </div>
          </div>
        );
      }

      return (
        <div className="title">
          <div
            className="title-items"
            onContextMenu={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            <Folder />
            <span className="title-folder title-text">{fNode.title}</span>

            {canEdit && (
              <FolderNodeActions
                fNode={fNode}
                type={type}
                source={location}
                valnavFolderMutation={valnavFolderMutation}
                setNameField={setNameField}
                setError={setError}
                onAcceptFiles={onAcceptFiles}
                uploadValNav={uploadValNav}
                location={location}
              />
            )}
            {fNode.reserveCategory && (
              <span className="title-reservecategory">{fNode.reserveCategory}</span>
            )}
          </div>

          {canEdit && highlightedFolder === fNode.id && (
            <div className="msg">{uploadMutation.isLoading && <IconSpinner />}</div>
          )}
        </div>
      );
    },
    [
      isReadonly,
      canEdit,
      location,
      nameField,
      setNameField,
      updateFolder,
      setError,
      onAcceptFiles,
      highlightedFolder,
      type
    ]
  );

  const scrollToTreeNode = useCallback(
    (nodeKey) => {
      const treeInstance = treeRef.current;
      if (!treeInstance) {
        return;
      }
      treeInstance.scrollTo({ key: nodeKey, align: "top" });
    },
    [treeRef]
  );

  const forecastNode = useCallback(
    function (forecastTwNode, hasTypeWellSaveSucceeded) {
      // prevent new folder context menu from showing up on ForecastDetails
      function disableContextMenu(event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (forecastTwNode.isEdit && forecastTwNode.editLocation === location) {
        return (
          <div className="title">
            <div className="title-items">
              <BaseInput
                data-testid="type-well-name-input"
                value={nameField}
                onChange={setNameField}
                autoFocus
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onKeyDown={async (e) => {
                  if (e.key === "Enter" || e.key === "Escape") {
                    const cloneNode = { ...forecastTwNode };
                    cloneNode.isEdit = false;
                    memoizedUpdateTypeWellDataWithParentNode(cloneNode);

                    if (e.key === "Enter") {
                      onSaveTypeWell(forecastTwNode, nameField);
                    } else if (e.key === "Escape") {
                      setNameField(cloneNode.title);
                    }
                  }
                }}
              />
            </div>
          </div>
        );
      }

      return (
        <TitleContainer
          className="title"
          title=""
          key={forecastTwNode.id}
          isValNavFcst={forecastTwNode.source}
          selected={selectedTypeWell?.id === forecastTwNode.id}>
          <TitleItemWrapper className="title-items">
            <div onContextMenu={disableContextMenu}>
              <Popover
                placement="right"
                content={
                  <ForecastDetails
                    arpsItem={forecastTwNode}
                    declineType={declineType}
                    getTitle={getTitle}
                    hasTypeWellSaved={hasTypeWellSaveSucceeded}
                    type={type}
                  />
                }>
                <TitleWrapper>
                  <ForecastTitleWrapper
                    onClick={() => {
                      if (type === TYPE_WELLS) {
                        addToCheckedTypeWells(checkedForecasts, forecastTwNode);
                        undoRedoManager.reset();
                      }
                    }}>
                    {getTitle(forecastTwNode, type)}
                  </ForecastTitleWrapper>
                  {IS_INTERNAL_ENV && forecastTwNode.source && (
                    <Button
                      className={"visible-on-hover"}
                      type={"text"}
                      loading={valnavFolderMutation.isLoading}
                      onClick={() => onUpdateValNavTypeWell(forecastTwNode)}
                      shape={"circle"}
                      icon={<Refresh />}
                    />
                  )}
                </TitleWrapper>
              </Popover>
            </div>

            {
              <ForecastNodeActions
                fcstTwNode={forecastTwNode}
                type={type}
                source={location}
                scrollToTreeNode={scrollToTreeNode}
                widgetProps={widgetProps}
                setNameField={setNameField}
                setChecked={addToCheckedTypeWells}
                setError={setError}
              />
            }
          </TitleItemWrapper>
        </TitleContainer>
      );
    },
    [
      location,
      nameField,
      memoizedUpdateTypeWellDataWithParentNode,
      selectedTypeWell,
      type,
      checkedForecasts,
      undoRedoManager.reset,
      declineType,
      getTitle,
      onUpdateValNavTypeWell,
      scrollToTreeNode,
      widgetProps
    ]
  );

  // Resets upload errors when changing projects
  useEffect(() => {
    setError(null);
    memoizedSetValnavError(null);
    setWarning(null);
    refetch({
      req: {
        projectId: project?.projectId,
        type: type
      }
    });
  }, [project]);
  useEffect(() => {
    setCanEdit(
      (type === USER_ARPS && projectPermissions?.canEditForecasts) ||
        (type === TYPE_WELLS && projectPermissions?.canEditTypeWells)
    );
  }, [type, projectPermissions]);

  const onMenuItemClick = useCallback(
    async (e) => {
      if (e.key === "newFolder" && projectPermissions?.canEditTypeWells) {
        await newFolder();
      }
    },
    [projectPermissions]
  );

  // This is done to prevent rapid, unnecessary highlighting of folders when a user quickly hovers over multiple folders.
  const setHighlightedFolderDebounced = debounce(setHighlightedFolder, 100);

  const onDrop = useCallback((info) => {
    const isFolder = info.dragNode?.isFolder ?? false;
    let dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    let dropPosition = info.dropPosition;

    if (info.dropToGap) {
      const parentNodeId = isFolder ? info.node.parentId : info.node.folderId;
      dropKey = parentNodeId ?? "";
    } else {
      const dragParentNodeId = isFolder ? info.dragNode.parentId : info.dragNode.folderId;
      const dragNode = info.dragNode;
      const dropNode = info.node;
      const droppedToSameFolder =
        dragNode?.folderId == dropNode.folderId || dragParentNodeId === dropNode.key; //parent node is same as drop node
      if (droppedToSameFolder) {
        //dropping to the same folder previously and dropToGap is false
        //so the user must want to drop to the top of the folder
        dropPosition = 0;
      }
    }

    if (!info.node.isFolder) {
      if (!info.dropToGap) {
        dropPosition += 1;
      }
      //can't drop on to a forecast so we move it to the node that
      //holds the forecast we're trying to drop to
      dropKey = info.node.folderId;
    }

    const dropLevel = info.node.pos.split("-").length;
    const dropToRoot = dropLevel === 2;

    if (dropToRoot) {
      //dropped to root node so set dropPosition to 0 because
      //user wants to drop it at the top
      dropPosition = 0;
    }
    if (!isFolder) {
      const oldFolderId = info.dragNode.folderId;
      const input = {
        forecastId: dragKey,
        oldFolderId: oldFolderId,
        newFolderId: dropKey,
        type: type,
        order: dropPosition
      };
      moveTypeWell.move(input);
    } else {
      toast.error("Unable to move type well");
    }
  }, []);

  const renderNode = useCallback(
    (item) => {
      return (
        <TreeNodeTitle
          key={item.title}
          className={`tree-title ${
            canEdit && highlightedFolder && highlightedFolder === item.id && item.isFolder
              ? "drag-drop"
              : ""
          } ${nameChangeError ? "title-has-error" : ""}`}
          onDragOver={(e) => {
            // The default drag over behavior of an element is to disable dropping,
            // so in order to allow dropping the handler needs to prevent this default behavior.
            e.preventDefault();
          }}
          onDragEnter={(e) => {
            e.preventDefault();
            // "item.id" is unique across all folders even ones with the same names but different reserve categories.
            if (
              e.dataTransfer &&
              e.dataTransfer.items &&
              e.dataTransfer.items.length > 0
            ) {
              // "item.id" is unique across all folders even ones with the same names but different reserve categories.
              setHighlightedFolderDebounced(item.id);
            }
          }}
          onDrop={async (ev) => {
            ev.preventDefault();
            const files = onAcceptFiles(getFilesFromEvent(ev));
            if (
              ev.dataTransfer &&
              ev.dataTransfer.items &&
              ev.dataTransfer.items.length > 0
            ) {
              await uploadValNav(item.folderName, item.folderId, files);
            }
            setHighlightedFolder(null);
          }}
          onDragLeave={(e) => {
            // When you have nested elements, and you drag over a child element, the parent will fire an onDragLeave
            // event even though you're technically still "on" the parent.
            // To prevent this, check if the related target is a child of the event target.
            const targetBeingLeft = e.currentTarget;
            const targetBeingEntered = e.relatedTarget;
            if (!targetBeingLeft.contains(targetBeingEntered)) {
              setHighlightedFolder(null);
              // Prevent from executing after the drag operation has ended.
              // This is necessary because the debounced function might still execute and incorrectly highlight the folder.
              setHighlightedFolderDebounced.cancel();
            }
          }}>
          {item.isFolder && folderNode(item)}
          {!item.isFolder && forecastNode(item, hasTypeWellSaveSucceeded)}
        </TreeNodeTitle>
      );
    },
    [
      canEdit,
      highlightedFolder,
      nameChangeError,
      onAcceptFiles,
      getFilesFromEvent,
      setHighlightedFolder,
      folderNode,
      forecastNode,
      hasTypeWellSaveSucceeded
    ]
  );

  const checkNodeCondition = useCallback(
    (node) => {
      return !node.isEdit && canEdit && type === TYPE_WELLS;
    },
    [canEdit, type]
  );

  const memoizedItems = useMemo(() => {
    return projectPermissions.canEditTypeWells ? items : [];
  }, [projectPermissions.canEditTypeWells, items]);

  return (
    <UserArpsContainer>
      <GridContainer>
        <div>
          {canEdit && !widgetProps && (
            <>
              <p className="info">
                Drag and drop a ValNav or Mosaic Excel decline file to a folder.
              </p>
              {error && <ErrorComponent>{error}</ErrorComponent>}
              {valnavError && <ErrorComponent>{valnavError}</ErrorComponent>}
              {warning && <WarningComponent>{warning}</WarningComponent>}
              {nameChangeError && (
                <ErrorComponent>Duplicated folder name detected.</ErrorComponent>
              )}
            </>
          )}
        </div>
        {type === TYPE_WELLS ? (
          <Checkbox
            style={{ marginLeft: "5px" }}
            checked={normalizeTypeWell}
            onChange={(val) => {
              dispatch(setNormalizeTypeWell(val.target.checked));
            }}>
            <NormalizeTypeWellWrapper>
              Normalize Type Well
              <Tooltip title="When toggled off type wells will not be normalized.">
                <Info />
              </Tooltip>
            </NormalizeTypeWellWrapper>
          </Checkbox>
        ) : hasFeature("Forecast Compare") ? (
          <>
            <ForecastSelectWrapper>
              <ForecastModeSelect
                value={forecastMode}
                onChange={(value) => {
                  dispatch(setForecastMode(value));
                }}>
                <Tooltip title="Select the forecast mode">
                  <Button>
                    <Icon
                      path={mdiTrendingDown}
                      className="activity-action-icon"
                      size={1}
                    />
                  </Button>
                </Tooltip>
              </ForecastModeSelect>
              <ToggleWrapper>
                <Switch
                  checked={useForecastStartDate}
                  onChange={(val) => {
                    dispatch(setUseForecastStartDate(val));
                  }}
                  size="small"></Switch>

                <span style={{ marginLeft: "10px" }}>Show from start date</span>
              </ToggleWrapper>
            </ForecastSelectWrapper>
          </>
        ) : (
          <div></div>
        )}
        <BaseDivider />
        <ActionBar haspadding={!canEdit}>
          <BaseInput
            type="text"
            placeholder="Filter Entity Name"
            value={searchValue}
            onChange={setSearchValue}
            autoFocus
          />
          {canEdit && (
            <Actions>
              <Tooltip placement="top" title="Create New Folder">
                <ForecastHeaderActionButton
                  data-testid="forecast-new-folder-button"
                  onClick={async (evt) => {
                    await newFolder();
                    evt.stopPropagation();
                    evt.preventDefault();
                  }}>
                  <CreateNewFolder fontSize="large" />
                </ForecastHeaderActionButton>
              </Tooltip>
            </Actions>
          )}
        </ActionBar>
        <TypeWellTreeContainer ref={ref} className="tree-wrapper">
          {loading && <IconSpinnerStyled />}
          {treeData.length === 0 && (
            <p className="info">To get started, create a folder and add a type well.</p>
          )}
          <Dropdown
            trigger={["contextMenu"]}
            menu={{
              items: memoizedItems,
              onClick: onMenuItemClick
            }}>
            <Tree
              checkable
              selectable
              showIcon={false}
              showLine={false}
              treeData={treeData}
              draggable={checkNodeCondition}
              defaultExpandedKeys={expandedKeys}
              expandedKeys={expandedKeys}
              onExpand={onTreeExpand}
              checkedKeys={checkedKeys}
              onDrop={onDrop}
              onCheck={onCheckChanged}
              height={height}
              ref={treeRef}
              titleRender={renderNode}
            />
          </Dropdown>
        </TypeWellTreeContainer>
      </GridContainer>
    </UserArpsContainer>
  );
}

const items: MenuProps["items"] = [
  {
    label: "New Folder",
    key: "newFolder",
    icon: <CreateNewFolder />
  }
];

const ForecastHeaderActionButton = styled.button`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: transparent;
  color: ${(props) => (props.disabled ? "#ddd" : "#a2aaad")};

  &:hover {
    color: ${(props) =>
      props.disabled
        ? "#ddd"
        : props.danger
        ? "var(--color-danger)"
        : "var(--color-primary)"};
  }

  &[disabled]:hover > svg {
    cursor: default;
  }
`;

// Has padding makes up for the missing filter button, which creates padding because of its icon size
export const ActionBar = styled.div`
  display: flex;
  text-align: left;
  padding: 0 10px;
  padding: ${(props) => (props.haspadding ? "5px 10px" : "0 10px")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

const GridContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-rows: auto auto auto auto minmax(100px, 1fr) auto;
  grid-template-columns: minmax(0, 1fr);

  .info {
    margin-left: 5px;
  }

  .ant-tree-switcher:hover::after {
    border-color: var(--color-primary);
    align-items: center;
    justify-content: center;
  }

  .ant-tree-switcher.ant-tree-switcher_open {
    transform: rotate(90deg);
  }

  .ant-tree-switcher svg {
    display: none;
  }

  .ant-tree-switcher-noop {
    opacity: 0.25;
    pointer-events: none;
  }

  .shapefile-leaf .ant-tree-switcher-noop {
    opacity: 0;
    pointer-events: none;
  }

  .ant-tree-switcher::after {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    left: 5px;
    width: 7px;
    height: 7px;
    border-style: solid;
    border-color: var(--color-text);
    border-width: 0 1px 1px 0;
    transform: rotate(315deg);
  }

  .ant-tree {
    height: 100%;
  }

  .ant-tree-list {
    width: 100%;
    height: 100%;
  }

  .ant-tree-treenode {
    display: grid;
    grid-template-columns: auto auto auto minmax(0, 1fr);
    width: 100%;
    border-bottom: 4px solid transparent;
    margin-bottom: 0 !important;
    .ant-tree-node-content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .ant-tree-iconEle {
      line-height: 26px;
    }

    .ant-tree-title {
      width: 100%;
    }
  }

  .ant-tree-list-scrollbar {
    .ant-tree-list-scrollbar-thumb {
      border-radius: 0 !important;
      background: var(--color-scrollbar-thumb) !important;
    }
  }
`;

const ForecastSelectWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
  flex-direction: column; /* Add this to stack items vertically */

  > :first-child {
    flex-grow: 0;
  }
`;

const ToggleWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
`;
const TitleItemWrapper = styled.div`
  //show button only on hover

  .visible-on-hover {
    opacity: 0;
  }

  &:hover {
    .visible-on-hover {
      transition: all var(--duration);
      opacity: 1;
    }
  }
`;

const TypeWellTreeContainer = styled.div`
  position: relative;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) auto;
  height: 100%;
`;

const IconSpinnerStyled = styled(IconSpinner)`
  // Overlay the IconSpinner over the text and other items in the container.
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const TreeNodeTitle = styled.div`
  text-align: left;
  width: 100%;

  &.drag-drop {
    border: 2px dashed gray;
    padding: 0 5px;
  }

  .title {
    width: 100%;
    cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5rem;

    .title-items {
      display: flex;
      width: 100%;
      padding-right: 8px;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 32px;

      .title-folder {
        padding-left: 5px;
      }

      .title-text {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title-reservecategory {
        display: block;
        background-color: #dfdfdf;
        padding: 1px 3px;
        border-radius: 8px;
        font-size: 10px;
        font-weight: bold;
      }

      .edit-icon,
      .edit-export,
      .info-icon {
        margin-left: auto;
        display: flex;

        svg {
          flex-shrink: 0;
          opacity: 0;
          transition: all var(--duration);

          &:hover {
            color: var(--color-primary);
          }
        }
      }

      &:hover {
        .edit-icon,
        .edit-export,
        .info-icon {
          display: flex;
        }

        svg {
          opacity: 1;
        }
      }

      .input {
        height: 24px;
      }
    }
  }
`;

const ErrorComponent = styled.div`
  color: red;
  width: 100%;
  max-width: fit-content;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
`;

const WarningComponent = styled.div`
  color: var(--orange);
  width: 100%;
  max-width: fit-content;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
`;

const NormalizeTypeWellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TitleContainer = styled.div`
  color: ${(props) => (props.selected ? "var(--color-primary)" : "black")};
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  padding-left: 5px;
`;

const ForecastTitleWrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TitleWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 30px;
  align-items: center;
  justify-content: flex-start;

  .ant-btn {
    padding: 5px;
    color: #a2aaad;
  }
`;
