import { useQuery } from "react-query";

import axios from "axios";

import { NetworkFraction } from "components/map/hooks/useGetFacilityNetworkFraction";

import { DATA_SERVICE_ADDRESS } from "../../../constants/app.constants";

const API_URL = `${DATA_SERVICE_ADDRESS}/api/v1/midstream`;

export const FetchWellDownstreamReportKey = "fetchWellDownstreamReport";

const fetchWellReport = async (params) => {
  const month = params.queryKey[1] as string;
  const wellIds = params.queryKey[2] as string[];
  const ids = wellIds
    .map((id) => `wellIds=${id.replaceAll("/", "").replaceAll("-", "").trim()}`)
    .join("&");
  const monthQuery = month == null ? "" : `month=${month}&`;
  const response = await axios.get<{
    wells: string[];
    month: string;
    network: NetworkFraction[];
  }>(`${API_URL}/well-downstream-report?${monthQuery}${ids}`);
  if (!response.data) {
    return { wells: [], month: "", network: [] };
  }
  return response.data;
};

const useFetchWellReport = (params: { ids: string[]; month: string }) => {
  const data = useQuery<{ wells: string[]; month: string; network: NetworkFraction[] }>(
    [FetchWellDownstreamReportKey, params.month, params.ids],
    fetchWellReport,
    {
      refetchOnWindowFocus: false
    }
  );
  return data;
};

export default useFetchWellReport;
