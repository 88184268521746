import { SHAPEFILE_NAME_LABELS_LAYER } from "constants/mapLayers.constants";

import { IEditableProjectLayer } from "components/project/layers/hooks";
import { Shapefile } from "components/project/shapefiles/queries";

import { getUncheckedFeatures } from "../../getUncheckedFeatures";
import {
  LABEL_SHAPEFILE_TYPE,
  POINT_SHAPEFILE_TYPE,
  STROKE_SHAPEFILE_TYPE
} from "../constants";

interface AddShapefileLayersParams {
  addLayer;
  mapbox: mapboxgl.Map;
  layerList: IEditableProjectLayer[];
  layerName: string;
  shapefile: Shapefile;
  shapefileFeatures;
}

export function addShapefileLayers(params: AddShapefileLayersParams) {
  const { addLayer, mapbox, layerList, layerName, shapefile, shapefileFeatures } = params;
  mapbox.addSource(layerName, {
    type: "geojson",
    data: shapefile.featureCollection,
    tolerance: 0 // default is 0.375, lowered because shapefiles/polygons in EVA-2508 and EVA-2918 were not rendering properly at certain zoom levels
  });

  /**
   * Other shapefile layers
   */
  const layerStrokeName = `${layerName}${STROKE_SHAPEFILE_TYPE}`;
  const layerPointName = `${layerName}${POINT_SHAPEFILE_TYPE}`;
  const layerLabelName = `${layerName}${LABEL_SHAPEFILE_TYPE}`;

  const geomKeys =
    shapefileFeatures?.[layerName]?.features
      ?.filter((f) => f.checked)
      .map((f) => f.shapefileGeomId) || [];

  const uncheckedFeatures = geomKeys?.length
    ? geomKeys
    : getUncheckedFeatures(layerName, shapefileFeatures, shapefile);

  const textOpacity = layerList.find((l) => l.name === SHAPEFILE_NAME_LABELS_LAYER)
    .isChecked
    ? ["match", ["get", "shapefileGeomId"], uncheckedFeatures, 1, 0]
    : 0;

  const hasPointGeom = (shapefile?.featureCollection?.features ?? []).some(
    (geom) => geom.geometry?.type === "Point"
  );

  // Point layer
  if (hasPointGeom) {
    addLayer(
      {
        id: layerPointName,
        type: "circle",
        source: layerName,
        layout: {},
        paint: {
          "circle-color": ["get", "color"],
          "circle-opacity": [
            "match",
            ["get", "shapefileGeomId"],
            uncheckedFeatures,
            ["get", "opacity"],
            0
          ],
          "circle-stroke-color": ["get", "strokeColor"],
          "circle-stroke-opacity": [
            "match",
            ["get", "shapefileGeomId"],
            uncheckedFeatures,
            ["get", "opacity"],
            0
          ],
          "circle-stroke-width": 1,
          "circle-radius": {
            base: 1.8,
            stops: [
              [6, 2],
              [15, 25],
              [22, 30]
            ]
          }
        }
      }
      // ,
      // beforeLayer
    );
  }

  // Stroke Layer
  addLayer(
    {
      id: layerStrokeName,
      type: "line",
      source: layerName,
      layout: {},
      paint: {
        "line-color": ["get", "strokeColor"],
        "line-width": ["get", "thickness"],
        "line-opacity": [
          "match",
          ["get", "shapefileGeomId"],
          uncheckedFeatures,
          ["get", "opacity"],
          0
        ]
      }
    },
    hasPointGeom ? layerPointName : null
    // beforeLayer
  );
  // Label Layer
  addLayer({
    id: layerLabelName,
    type: "symbol",
    source: layerName,
    layout: {
      "text-field": shapefile.layerName,
      "text-size": 9,
      "text-variable-anchor": ["top"],
      visibility: layerList.find((l) => l.name === SHAPEFILE_NAME_LABELS_LAYER).isChecked
        ? "visible"
        : "none"
    },
    paint: {
      "text-color": "#000",
      "text-halo-color": "#fff",
      "text-halo-width": 2,
      "text-opacity": textOpacity
    }
  });

  // Fill Layer
  addLayer(
    {
      id: layerName,
      type: "fill",
      source: layerName,
      layout: {},
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": [
          "match",
          ["get", "shapefileGeomId"],
          uncheckedFeatures,
          ["get", "opacity"],
          0
        ]
      }
    },
    layerStrokeName
  );
}
